@import '../../App.scss';

.font-resizer-container {
  display: flex;
  align-items: center;
}

.font-size-label {
  font-family: 'Raleway';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.5px;
  color: #4a4a4a;
  margin-left: 16px;
  margin-right: 16px;
}

.sort-descending-img {
  height: 24px;
  width: 24px;
  cursor: pointer;
  content: url('../../images/icons/sort-descending.png');
}

.sort-ascending-img {
  height: 24px;
  width: 24px;
  cursor: pointer;
  content: url('../../images/icons/sort-ascending.png');
}