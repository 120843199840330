@import '../../App.scss';


.score-toggle {
    width: 100%;
    height: 44px;
    border-radius: 25.5px;
    background-color: #F6F5F4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 0;
    position: relative;
    cursor: pointer;
}