@import '../../App.scss';

.smart-leaflets-screen {
    min-height: calc(100vh - 80px - 50px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
    .screen-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 25px;
        .screen-title__back {
            width: 32px;
            height: 32px;
            min-width: 32px;
            min-height: 32px;
            background-color: $very-light-pink-two;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 19px;
            cursor: pointer;
            .screen-title__back__img {
                width: 16px;
                height: 16px;
                content: url('../../images/voltar-laranja.svg');
            }
        }
        .screen-title__text {
            font-family: 'AccordAlt';
            font-size: 30px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.53;
            letter-spacing: 0.5px;
            color: $greyish-brown;
            margin-bottom: 0px;
        }
    }
}

.leaflets-content {
    display: flex;
    flex-direction: row;
    height: 100%;
    .leaflets-content__side-menu {
        display: flex;
        flex-direction: column;
        margin-right: 86px;
    }
    .leaflets-content__groups {
        -ms-box-orient: horizontal;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        -webkit-flex-wrap: wrap;
        display: flex;
        width: 100%;
        flex-wrap: wrap;            
        overflow-y: hidden;
        overflow-x: auto;
        padding-bottom: 12px;
        .leaflets-group {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 154px;
            height: 65px;
            word-wrap: break-word;   
            margin-right: 16px;
            position: relative;
            cursor: pointer;
            .leaflets-group__img {
                width: 154px;
                height: 65px;
                object-fit: contain;              
            }
            .leaflets-group__title {
                font-family: 'Raleway';
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.34px;
                text-align: center;
                text-justify: center;
                color: $white-three;
                position: absolute;
                width: 130px;
                max-height: 57px;
                word-wrap: break-word;
            }
        }
    }
}

.leaflet-card-content {
    display: flex;
    padding-left: 12px;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    .leaflet-card-content__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 182px;
        padding-top: 8px;
        padding-bottom: 8px;
        // min-height: 40px;
        .leaflet-card-content__col {
            .leaflet-card-content__title {
                font-family: 'Raleway';
                font-size: 13px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.65px;
                color: $charcoal-grey;   
                margin-bottom: 0px;               
            }
            .leaflet-card-content__subtitle {
                font-family: 'Raleway';
                font-size: 13px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.65px;
                color: $brownish-grey;
                margin-bottom: 0px;
            }
        }
        .leaflet-card-content__arrow-icon {
            width: 11px;
            height: 11px;
            object-fit: contain;
            content: url('../../images/avancar-cinza.svg');
        }
    }
}