@import '../../../App.scss';

.disease-screen-container {
    height: calc(100vh - 80px - 50px);
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 12px;
}
.screen__title {
    display: flex;
    align-items: center;
    margin-left: 50px;
    margin-bottom: 20px;
    .screen__title__back {
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        background-color: $very-light-pink-two;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 19px;
        cursor: pointer;
        .screen__title__back__img {
            width: 16px;
            height: 16px;
            content: url('../../../images/voltar-laranja.svg');
        }
    }
    .screen__title__text {
        min-width: 140px;
        max-height: 48px;
        font-family: 'AccordAlt';
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.4px;
        color: $greyish-brown;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}
.disease-content__markdown {
    height: calc(100vh - 80px - 50px - 60px);
    width: auto;
    overflow: auto;
}
.markdown {
    margin-left: 26px;
    width: auto;
    padding-top: 31px;
    hr {
        margin-top: 0px;
    }
    ul li {
        margin-bottom: 12px;
    }
    .markdown__paragraph {
        font-family: 'Raleway';
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: 0.5px;
        color: $greyish-brown;
        margin-bottom: 12px;
        ul li {
            margin-bottom: 0px;
        }
    }
    .markdown__strong {
        font-weight: bold;
    }
    .markdown__highlighted {
        background-color: yellow;
    }

    .markdown__heading {
        font-family: 'Raleway';
        font-size: 18px !important;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.11;
        letter-spacing: 0.5px;
        color: $dark-blue-grey;
    }
    .markdown__heading span {
        font-weight: 600;
    }
    .markdown__emphasis {
        font-family: 'Raleway';
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.57;
        letter-spacing: 0.5px;
        color: $greyish-brown;
        margin-bottom: 0px;
    }
    .markdown__link {
        font-family: 'Raleway';
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 1.57;
        letter-spacing: 0.5px;
        color: $dusty-orange;
        margin-bottom: 0px;
    }
  
    .markdown__list-item {
        font-family: 'Raleway';
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: 0.5px;
        color: $greyish-brown;
    }
    .markdown__highlight {
        background-color: yellow;
    }
    .markdown__image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        .markdown__image {
            object-fit: contain;
            max-height: 500px;
        }
        .markdown__image__zoom-icon {
            opacity: 1 !important;
            width: 32px;
            height: 32px;
            object-fit: contain;
            content: url('../../../images/lupa.svg');
            position: absolute;
            align-self: center;
        }
    }
    .markdown__code {
        font-family: 'Raleway';
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: 0.5px;
        color: $greyish-brown;
        padding: 8px;
    }
    .markdown__blockquote {
        display: flex;
        justify-content: center;
        width: 100%;
        .markdown__blockquote__content {
            width: 40%;
        }
    }
    .markdown__blockquote div {
        font-family: 'Raleway';
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.36px;
        color: $brown-grey-three;
    }
    .markdown__blockquote div a {
        color: $tangerine-two;
    }
    
    .markdown__medical-prescription__header {
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px solid #dcdcdc;
        .markdown__medical-prescription__header__logo {
            width: 90px;
            height: 34px;
            content: url('../../../images/wemeds-logo-semihor.svg');
            margin-bottom: 25px;
        }
        .markdown__medical-prescription__header__text {
            font-family: 'Raleway';
            font-size: 18px !important;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.11;
            letter-spacing: 0.5px;
            color: $dark-blue-grey;
            margin-bottom: 0px;
        }
    }
    .markdown__medical-prescription__header__subtitle {
        font-family: 'Raleway';
        font-size: 12px !important;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.11;
        letter-spacing: 0.5px;
        color: $very-light-pink;
        margin-bottom: 10px;
        margin-top: 2px;
    }
    .markdown__medical-prescription__footer {
       width: 100%;
       display: flex;
       align-items: center;
       justify-content: center;
       margin-bottom: 40px;
        .markdown__medical-prescription__footer__text {
            color: $orangey-yellow;
            margin-bottom: 0px;
            width: 200px;
            text-align: center;
        }
    }
}

.tab {
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-left: 20px;
    .tab__menu-containter {
        display: flex;
        width: 100%;
        z-index: 20;
        background-color: white;
        align-items: center;
        justify-content: center;
        border-bottom: solid 1px $light-periwinkle;
    }
    .tab__menu {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        height: 71px;
        width: 100%;
        position: relative;
        padding-right: 3px;
        .tab__menu__left {
            display: flex;
            flex-direction: row;
            padding-left: 10px;
            .tab__menu__left__title {
                display: flex;
                align-items: center;
                min-height: 47px;
            }
            .tab__menu__left__tabs {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                margin-left: 20px;
            }
        }
        .tab__menu__tab {
            max-width: 257px;
            min-width: 170px;
            height: 47px;
            border: solid 1px $light-periwinkle;
            border-radius: 6px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-bottom: none;
            background-color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .tab__menu__tab__text {
                font-family: 'Raleway';
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: 0.5px;
                text-align: center;
                color: $greyish-brown;
            }
        }
        .tab__menu__tab--selected {
            height: 52px;
            background-color: $white-three;
            border-bottom: none;
        }
    }
    .tab__content {
        display: flex;
        z-index: 1;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
    }
}
.disease-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    .disease-content__menu {
        display: flex;
        flex-direction: column;
        margin-top: 31px;
        height: calc(100vh - 80px - 50px - 60px - 31px - 5px);
        width: 231px;
        overflow: auto;
        overflow-x: hidden;
        .disease-content__menu__expand-btn {
            width: 40px;
            height: 25px;
            object-fit: contain;
            content: url('../../../images/button-right.svg');
            cursor: pointer;
            margin-bottom: 2px;
        }
        .disease-content__menu__item {
            width: 231px;
            height: 42px;
            min-height: 42px;
            border-radius: 4px;
            background-color: $white-four;
            margin-bottom: 2px;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-right: 16px;
            padding-left: 16px;
            .disease-content__menu__item__content {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
            .disease-content__menu__item__text {
                font-family: 'Raleway';
                font-size: 11px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.31px;
                color: $dark-blue-grey;
            }
            .disease-content__menu__item__right-icon {
                width: 14px;
                height: 14px;
                object-fit: contain;
                content: url('../../../images/avancar-cinza.svg');
            }
        }
        .disease-content__menu__item--selected {
            border: solid 1px $light-periwinkle;
            background-color: $white-three;
        }
        .disease-content__menu__item--hidden {
            width: 40px;
            height: 42px;
        }
    }
}

.img-modal {
    z-index: 10000;
    position: fixed;
    right: 0px;
    bottom: 0px;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(4, 4, 4, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    .img-modal__content {
        background-color: $white-three;
        display: flex;
        justify-content: center;
        position: relative;
        border-radius: 4px;
        padding-top: 24px;
        padding-bottom: 24px;
        padding-left: 64px;
        padding-right: 24px;
        .img-modal__content__image {
            object-fit: contain;
            max-height: 90vh;
        }
        .img-modal__content__close-icon {
            width: 32px;
            height: 32px;
            object-fit: contain;
            content: url('../../../images/fechar-3.svg');
            margin-left: 8px;
            cursor: pointer;
        }
    }
}

.disease-search {
    max-width: 420px;
    width: 100%;
    height: 47px;
    border-radius: 6px;
    border: solid 1px $light-periwinkle;
    border-bottom: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: $white-three;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 14px;
    padding-right: 23px;
    .disease-search__search-icon {
        width: 18px;
        height: 18px;
        object-fit: contain;
        content: url('../../../images/search.svg');
        margin-right: 12px;
    }
    .disease-search__text-input:focus {
        outline: none;
    }
    .disease-search__text-input {
        max-width: 290px;
        min-width: 105px;
        width: 100%;
        border: none;
        font-family: 'Raleway';
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $brown-grey;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .disease-search__menu {
        display: flex;
        align-items: center;
        // font icon
        .disease-search__font-img {
            width: 24px;
            height: 24px;
            object-fit: contain;
            content: url('../../../images/font-gray.svg');
            margin-right: 20px;
            cursor: pointer;
        }
        .disease-search__font-img:hover {
            width: 24px;
            height: 24px;
            object-fit: contain;
            content: url('../../../images/font-dark-gray.svg');
            margin-right: 20px;
            cursor: pointer;
        }
        .disease-search__font-img--orange {
            content: url('../../../images/font-orange.svg');
        }
        .disease-search__font-img--orange:hover {
            content: url('../../../images/font-orange.svg');
        }

        // pencil icon
        .disease-search__pencil-img {
            width: 24px;
            height: 24px;
            object-fit: contain;
            content: url('../../../images/gray-pencil.svg');
            margin-right: 20px;
            cursor: pointer;
        }
        .disease-search__pencil-img:hover {
            width: 24px;
            height: 24px;
            object-fit: contain;
            content: url('../../../images/dark-gray-pencil.svg');
            margin-right: 20px;
            cursor: pointer;
        }
        .disease-search__pencil-img--orange {
            content: url('../../../images/orange-pencil.svg');
        }
        .disease-search__pencil-img--orange:hover {
            content: url('../../../images/orange-pencil.svg');
        }

        // star icon
        .disease-search__star-img {
            width: 24px;
            height: 24px;
            object-fit: contain;
            content: url('../../../images/empty-star.svg');
            cursor: pointer;
        }
        .disease-search__star-img:hover {
            width: 24px;
            height: 24px;
            object-fit: contain;
            content: url('../../../images/dark-gray-star.svg');
            cursor: pointer;
        }
        .disease-search__star-img--orange {
            content: url('../../../images/orange-star.svg');
        }
        .disease-search__star-img--orange:hover {
            content: url('../../../images/orange-star.svg');
        }
    }
}

// slider
.disease-content__slider-container {
    background-color: $white-three;
    width: 133px;
    height: 45px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    position: absolute;
    z-index: 1;
    top: 52px;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.disease-content__slider {
    width: 133px !important;
    color: $very-light-pink-five !important;
}
.MuiSlider-markActive {
    background-color: #3eb5b1 !important;
    opacity: 1 !important;
}

// success toast
.disease-content__favorite-success-toast {
    background-color: $maize-two;
    width: 213px;
    height: 45px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.28);
    position: absolute;
    z-index: 1;
    top: 52px;
    right: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-right: 16px;
    padding-left: 16px;
}

.disease-content__white-star-img {
    width: 19px;
    height: 18px;
    object-fit: contain;
    content: url('../../../images/star-white.svg');
    margin-right: 8px;
}

.disease-content__favorite-success-msg {
    font-family: 'Raleway';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $greyish-brown;
}

.copyright-modal {
    z-index: 10000;
    position: fixed;
    right: 0px;
    bottom: 0px;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(4, 4, 4, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    .copyright-modal__content {
        background-color: $white-three;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
        border-radius: 4px;
        padding-top: 8px;
        padding-bottom: 19px;
        padding-left: 25px;
        padding-right: 31px;
        width: 277px;
        height: 180px;
        .copyright-modal__title {
            font-family: 'Raleway';
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
            color: $dark-blue-grey;
            margin-bottom: 7px;
        }
        .copyright-modal__description {
            font-family: Raleway;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.38px;
            color: $greyish-brown;
            margin: 0px;
        }
        .copyright-modal__close-icon {
            width: 18px;
            height: 18px;
            object-fit: contain;
            content: url('../../../images/fechar-2.svg');
            margin-left: 8px;
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
}

.colorful-circle {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    display: inline-block;
}
.colorful-circle--o {
    background-color: $circle-orange;
}
.colorful-circle--r {
    background-color: $circle-red;
}
.colorful-circle--g {
    background-color: $circle-green;
}
.colorful-circle--y {
    background-color: $circle-yellow;
}
.colorful-circle--b {
    background-color: $circle-black;
}
