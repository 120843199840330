@import './styles/constants/colors.scss';
@import './styles/constants/fonts.scss';
@import '../src/media-query';

/**************************************/
/* APP GENERAL */
/**************************************/
// .Toaster__manager-bottom {
//     bottom: 50px !important;
// }
// .Toaster__message-wrapper {
// margin-bottom: 40px !important;
//     height: 50px !important;
//     padding: 3px;
//     border-radius: 16px !important;
//     box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2) !important;
//     background-image: linear-gradient(52deg, #f84073 21%, #f67d40 62%, #fecf44 100%) !important;
//     display: flex !important;
//     align-items: center !important;
//     .Toaster__alert_text {
//         font-family: Raleway !important;
//         font-size: 14px !important;
//         font-weight: normal !important;
//         font-stretch: normal !important;
//         font-style: normal !important;
//         line-height: 1.33 !important;
//         letter-spacing: 0.45px !important;
//         text-align: center !important;
//         color: #ffffff !important;
//     }
// }

// .Toaster__alert_close {
//     color: #ffffff !important;
//     font-family: Raleway !important;
//     font-size: 30px !important;
//     display: flex !important;
//     align-items: center !important;
//     justify-content: center !important;
//     padding: 0 !important;
//     padding-right: 8px !important;
//     padding-top: 2px !important;
// }

// .Toaster__alert {
//     background-color: transparent !important;
//     box-shadow: none !important;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

body {
    font-family: 'Raleway';
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: white;
    // min-height: 100vh;
    min-height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}


.empty-list-img {
    content: url('./images/icons/empty-list.png');
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.justity-content-center {
    justify-content: center;
}
.align-content-center {
    align-content: center;
}

.form {
    .MuiFormControl-root {
        margin-top: 25px;
    }
}
a {
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}
.disable-copy {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
/**************************************/
/* DASHBOARD */
/**************************************/

.step-container {
    margin-top: -28px;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
    @include responsive(s) {
        display: none;
    }
}

.space-step-blank {
    width: 70px;
    height: 1px;
}

.register-mobile-edit-label {
    margin-top: 5px;
    margin-right: 10px;
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.23px;
    color: $pumpkin;
}

.space-step-on {
    width: 90px;
    height: 1px;
    border: solid 1px #1a3c47;
}

.space-step-off {
    width: 90px;
    height: 1px;
    border: solid 1px #b9b9b9;
}

.text-step {
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    color: #1a3c47;
    @include responsive(s) {
        font-size: 1rem;
    }
}

.head-step {
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    width: 140px;
    @include responsive(s) {
        position: unset;
        align-items: left;
        width: 17%;
    }
}

.head-step-text {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 7px;
    position: relative;
    display: flex;
    align-items: center;
    width: 152px;
    @include responsive(s) {
        position: unset;
        justify-content: flex-start;
        width: 100%;
    }

    .disabled {
        font-size: 11px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.46px;
        color: #b9b9b9;
    }
}

.dash-example {
    width: 100vw;
    content: url('./images/dash.png');
}

.progress-step {
    width: 25px;
    height: 25px;
    content: url('./images/step-progress.svg');
}

.progress-finish {
    width: 25px;
    height: 25px;
    content: url('./images/progress-finish.svg');
}

.progress-none {
    width: 13px;
    height: 13px;
    content: url('./images/progress-none.svg');
}

.next-svg {
    width: 17px;
    height: 17px;
    margin-top: auto;
    margin-left: 10px;
    content: url('./images/next-white.svg');
}

.back-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    content: url('./images/voltar-gray.svg');
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(226deg) brightness(104%) contrast(101%);
}
.advance-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    content: url('./images/avancar-white.svg');
}

.back-svg {
    width: 17px;
    height: 17px;
    margin-top: auto;
    margin-right: 10px;
    content: url('./images/back-white.svg');
}

.send-svg {
    width: 40px;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 20px;
    content: url('./images/send.svg');
}

.row {
    margin: 0px;
}

.step-container-mobile {
    display: none;
    @include responsive(s) {
        display: flex;
        margin-right: -7px;
        margin-left: -7px;
        border: solid 1px $ice-blue;
        padding: 18px 22px 18px 22px;
    }
}

.row-mobile {
    display: none;
    @include responsive(s) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        content: url('./images/avancar-gray.svg');
    }
}

/**************************************/
/* FREE REGISTER */
/**************************************/

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #5ab0ae !important;
}

.terms {
    font-size: 0.875rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.27px;
    color: #707070;
    @include responsive(s) {
        font-family: Raleway;
    }
}

.terms-link {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.27px;
    color: #ef7d00;
    @include responsive(s) {
        font-family: Raleway;
    }
}

.terms-link-web {
    margin-top: 20px;
    line-height: normal;
    font-style: normal;
    font-weight: 500;
    width: 300px;
    font-size: 13px;
    color: #414E52;
    &--dash {
        @extend .terms-link-web;
        text-decoration: underline;
    }
}

.step-header-register {
    margin-top: 50px;
    width: 460px;
    display: flex;
    align-items: center;
}

.step-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #1A3C47;
}

.caret-arrow {
    width: 36px;
    height: 36px;
    cursor: pointer;
    margin-right: 80px;
    content: url('./images/icons/caret-left.svg');
}

.message-box {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: #f5f5f5;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.27px;
    color: #4a4a4a;
    vertical-align: middle;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 12px;
    margin-top: 10px;
    @include responsive(s) {
        width: 100%;
        height: 100%;
        margin: 0;
    }
}

.success-back {
    margin-top: 30px;
    position: relative;
    width: 98px;
    height: 98px;
    border-radius: 50%;
    background-color: #fafafa;
}

.success-img {
    margin: 13px;
    position: absolute;
    align-content: center;
    width: 70px;
    height: 70px;
    content: url('./images/sucesso.svg');
}

.label-radio {
    margin-top: 30px;
    margin-bottom: -10px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    color: #414E52;
}

.radio-options {
    display: flex;
    @include responsive(s) {
        align-items: center;
    }
}

.main-text {
    margin-top: 45px;
    margin-bottom: 25px;
    font-size: 40px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1a3c47;
    @include responsive(s) {
        font-size: 2.5rem;
        font-family: AccordAlt;
        margin-bottom: unset;
    }
}

.main-text-mobile {
    display: none;
    @include responsive(s) {
        display: flex;
        font-size: 1.375rem;
        width: 80%;
        margin: auto;
        padding-top: 30px;
    }
}

.sub-text {
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    @include responsive(s) {
        width: 85%;
    }
}

.sub-text-mobile {
    @include responsive(s) {
        font-size: 1.125rem;
        margin-top: 5px;
    }
}

.register-container {
    align-items: center;
    align-content: center;
    text-align: center;
    margin: auto;
    width: 50%;
    @include responsive(s) {
        width: 100vw;
        padding-left: 7px;
        padding-right: 7px;
    }
}

.blank-header {
    min-height: 80px;
    width: 100vw;
    border: solid 1px #e1e2e6;
    background-color: #ffffff;
    @include responsive(s) {
        display: flex;
        align-items: center;
        position: unset;
        justify-content: center;
    }
}

.header-logo {
    cursor: pointer;
    position: relative;
    margin-top: 20px;
    margin-left: 15%;
    margin-bottom: 20px;
    width: 35px;
    height: 45px;
    content: url('./images/logo@3x.png');
    @include responsive(s) {
        position: unset;
        width: 30px;
        height: 38px;
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: 0px;
    }
}

/**************************************/
/* LOGIN */
/**************************************/

.page-container {
    height: 100vh;
    overflow: auto;
    @include responsive(s) {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
    }
}

.full-height {
    height: 100%;
}

.markdown_trophy {
    height: 14px;
    width: 14px;
    content: url('./images/icons/trophy-exclusive.svg');
}

.markdown_dose_link {
    font-family: 'Raleway';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0.5px;
    color: #C3AAF0;
    background-color: #DEBEFF30;
    cursor: pointer;
    padding: 0.5px 3px;
    border: solid 1px transparent;
    border-radius: 2px;
    &:hover {
        color: #EF7D00;
    }
}

.markdown_feature_link {
    font-family: 'Raleway';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0.5px;
    color: #EF7D00;
    background-color: #FFBE4630;
    cursor: pointer;
    padding: 0.5px 3px;
    border: solid 1px transparent;
    border-radius: 2px;
    &:hover {
        color: #EF7D00;
    }
}

.markdown_content_link {
    font-family: 'Raleway';
    cursor: pointer;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0.5px;
    color: #4D9FC3;
    background-color: #4D9FC310;
    padding: 0.5px 3px;
    border: solid 1px transparent;
    border-radius: 2px;
    &:hover {
        color: #4D9FC3;
    }
}

.login-container {
    height: 80vh;
    width: 550px;
    max-width: 100%;
    margin-top: 10vh;
    margin-left: 86px;
    padding-top: 10vh;
    background: #FFFFFF;
    border: 1px solid #E9ECED;
    border-radius: 12px;
    @include responsive(s) {
        display: flex;
        flex-direction: column;
        width: 100vw;
        margin: initial;
        margin-bottom: 37%;
        padding-left: 7px;
        padding-right: 7px;
    }
}

.right {
    float: right;
    margin-left: auto;
}

.left {
    float: left;
    margin-right: auto;
}

.container-banner {
    margin: auto;
    width: 70%;
    margin-left: 30%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    @include responsive(s) {
        display: flex;
        align-items: center;
        position: unset;
    }
    @include responsive(sm) {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    &--img-group-tablet {
        @include responsive(sm) {
            content: url(../src/images/Banner.svg);
            max-width: 90%;
        }
    }

    &--group-web {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.logo-word {
    width: 234.5px;
    height: 67px;
    content: url(../src/images/logo-word.svg);
} 

.description-banner {
    font-size: 20px;
    font-weight: 500;
    font-weight: normal;
    font-stretch: normal;
    margin-left: 18px;
    font-style: normal;
    letter-spacing: normal;
    color: #E9ECED;
    @include responsive(s) {
        display: none;
    }
}

.link-orange {
    cursor: pointer;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    color: #ef7d00 !important;
    display: flex;
    align-items: center;
}

.link-sm {
    font-weight: 600;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: #414E52;
    cursor: pointer;
}

.title-login {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5%;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    letter-spacing: normal;
    color: #1A3C47;
    @include responsive(s) {
        display: none;
    }
}

.title-login-mobile {
    display: none;
    @include responsive(s) {
        display: inline-block;
        text-align: center;
        padding-bottom: 40px;
        width: 90%;
        margin: auto;

        p {
            font-size: 2.5rem;
            font-weight: 500;
            font-family: AccordAlt;
            margin-bottom: -10px;
        }

        span {
            font-size: 1rem;
            line-height: 1.38;
        }
    }
}

.button-container {
    margin-top: 50px;
    @include responsive(s) {
        margin-top: 35px;
        margin-bottom: 40px;
    }
}
.centered-column {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.button-white-primary-web {
    height: 51px;
    border: none;
    width: 60%;
    margin-right: 20%;
    margin-left: 20%;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: #1A3C47;
    background-color: #FFFFFF;
    border: 1px solid #D2D9DC;
    border-radius: 32px;
    @include responsive(s) {

        width: 150px;
        margin-left: 0px;
    }
}

.button-white-primary-web:hover {
    height: 51px;
    border: none;
    width: 60%;
    margin-right: 20%;
    margin-left: 20%;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: #1A3C47;
    background-color: #FFFFFF;
    border: 1px solid #D2D9DC;
    border-radius: 32px;;
    opacity: 0.7;
    @include responsive(s) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
    }
}


.button-primary-web {
    border-radius: 34.5px;
    height: 55px;
    border: none;
    width: 60%;
    margin-right: 20%;
    margin-left: 20%;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    color: #ffffff;
    background-image: linear-gradient(to right, #f56f3d, #f1870b 57%, #f59e24);
    @include responsive(s) {
        width: 150px;
        margin-left: 0px;
    }
}
.button-primary-web:hover {
    border-radius: 25.5px;
    height: 55px;
    border: none;
    width: 60%;
    margin-right: 20%;
    margin-left: 20%;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    color: #ffffff;
    background-image: linear-gradient(to right, #f56f3d, #f1870b 57%, #f59e24);
    opacity: 0.7;
    @include responsive(s) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
    }
}

.button-primary {
    border-radius: 25.5px;
    height: 51px;
    border: none;
    width: 118px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    color: #ffffff;
    background-image: linear-gradient(to right, #f56f3d, #f1870b 57%, #f59e24);
    margin-left: 20px;
    @include responsive(s) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        margin-left: 0px;
    }
}
.button-primary:hover {
    border-radius: 25.5px;
    height: 51px;
    border: none;
    width: 118px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    color: #ffffff;
    background-image: linear-gradient(to right, #f56f3d, #f1870b 57%, #f59e24);
    opacity: 0.7;
    @include responsive(s) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
    }
}

.button-second {
    width: 151px;
    height: 51px;
    border-radius: 28px;
    border: solid 1px #a4a4a4;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    color: #a4a4a4;
    margin-right: 20px;
    @include responsive(s) {
        display: none !important;
    }
}

.button-primary:hover {
    border-radius: 25.5px;
    background-color: #ff8400;
    height: 51px;
    border: #ff8400;
    min-width: 115px;

    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    color: #ffffff;
}

.background-login {
    height: 100%;
    position: relative;
    background-size: cover;
    &--1 {
        @extend .background-login;
        background-image: url('./images/background-login-1.png');
    }
    &--2 {
        @extend .background-login;
        background-image: url('./images/background-login-2.png');
    }
    &--3 {
        @extend .background-login;
        background-image: url('./images/background-login-3.png');
    }
    @include responsive(s) {
        position: unset;
        display: flex;
        align-items: center;
        max-height: 70px;
        background-image: none;
        border: solid 1px #e1e2e6;
    }
}

.logo {
    position: relative;
    margin-top: 20px;
    margin-left: 10px;
    margin-bottom: 5vh;
    width: 128px;
    height: 128px;
    content: url('./images/logo-white.svg');
    @include responsive(s) {
        width: 30px;
        height: 38px;
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: 0px;
    }
}

.display-mobile {
    margin-left: -22px;
    bottom: 0;
    margin-top: auto;
    position: absolute;
    width: 60vh;
    content: url('./images/mobile-image.png');
    @include responsive(s) {
        display: none;
    }
}

.link-google-play {
    position: relative;
    margin-right: 10px;
    width: 175px;
    height: 47px;
    object-fit: contain;
    content: url('./images/download-google-play.png');
    @include responsive(s) {
        display: none;
    }
}

.link-apple-store {
    margin-left: 10px;
    position: relative;
    width: 160px;
    height: 47px;
    object-fit: contain;
    content: url('./images/download-app-store.png');
    @include responsive(s) {
        display: none;
    }
}

.button-container-login {
    margin-top: 50px;
    @include responsive(s) {
        margin-top: 35px;
        display: flex;
        justify-content: center;
        a {
            display: none;
        }
    }
}

.mobile-arrow {
    display: none;
    @include responsive(s) {
        display: flex;
        content: url('../src/images/avancar-white.svg');
        align-items: center;
    }
}

.second-step-register-mobile {
    @include responsive(s) {
        padding-left: 7px;
        padding-right: 7px;
        width: 100%;
    }
}

.simple-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.simple-col {
    display: flex;
    flex-direction: column;
}
.line-through {
    text-decoration: line-through;
}
.steps__footer__advance-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    content: url('./images/avancar-white.svg');
}

/**************************************/
/* INPUTS */
/**************************************/
.check-icn {
    width: 20px;
    height: 20px;
    content: url('./images/confirm-icn.png');
}

.helpText {
    margin-top: -2px;
    width: 100%;
    // height: 40px;
    padding: 10px;
    position: relative;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border: solid 1px #e8ebec;
    background-color: #ffffff;

    text-align: center;
    align-content: center;
    align-items: center;

    font-size: 13px;
    letter-spacing: 0.54px;
    color: #4a4a4a;
}

.blankHelpText {
    width: 100%;
}

.MuiFilledInput-underline::before {
    display: none;
}

.MuiLabel-shrink {
    background-color: #ffffff !important;
}

.MuiInputBase-input:focus {
    background-color: #ffffff !important;
    border-radius: 6px !important;
    border: solid 1px #e8ebec !important;
}

.MuiFormHelperText-root.Mui-error {
    font-family: Raleway;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.54px;
    color: #e04444;
}

.Mui-focused {
    background-color: #ffffff !important;
    color: #9199a1 !important;
}

.MuiFilledInput-root {
    background-color: #f6f6f6 !important;
    // color: #9199a1 !important;
}

.MuiFilledInput-underline::after {
    display: none !important;
}
.action {
    .MuiFilledInput-underline::after {
        display: none !important;
    }
}

.filled {
    .MuiFilledInput-root {
        // background-color: #ffffff !important;
        border-radius: 6px !important;
        border: solid 1px #e8ebec !important;
    }
    .MuiInputBase-input:focus {
        // background-color: #ffffff !important;
        border: none !important;
    }
}

.MuiFormHelperText-root {
    color: #f44336 !important;
}

.error {
    .MuiFilledInput-root {
        background-color: #ffffff !important;
        top: 0;
        border-radius: 6px;
        border-top: 2px solid #f44336 !important;
    }
    .MuiFormLabel-root.Mui-error {
        color: #9199a1 !important;
    }
    .MuiFilledInput-underline::after {
        top: 0;
        border-radius: 6px;
        border: none !important;
        border-top: 2px solid #e04444 !important;
    }
    .MuiInputBase-input:focus {
        background-color: #ffffff !important;
        border: none !important;
    }
}

.validate {
    .MuiFilledInput-underline:after {
        border-bottom: none;
    }
    .MuiFormLabel-root.Mui-error {
        color: #9199a1;
    }
    .MuiFilledInput-root {
        background-color: #ffffff !important;
        top: 0;
        border-radius: 6px;
        border-top: 2px solid #5ab0ae !important;
    }
    .MuiInputBase-input:focus {
        background-color: #ffffff !important;
        border: none !important;
    }
}
