@import '../../App.scss';


.input {
    width: 100%;
    height: 50px;
    display: flex;
    border-bottom: solid 1px $very-light-pink-four;
    padding-bottom: 11px;
    margin-top: 20px;
    flex-direction: column;
}
.input__title {
    font-family: 'Raleway';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: $bluey-grey;  
}
.input__value {
    font-family: 'Raleway';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    color: $greyish-brown;
    margin: 0px;
    margin-top: 3px;
}