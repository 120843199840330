@import '../../App.scss';

.wemeds-loading {
    // position: absolute;
    background-color: white;
    // opacity: 0.6;
    display: flex;
    align-items: center;
    justify-content: center;
    // top: 00px;
    width: 100%;
    // height: calc(100vh - 80px - 50px);
    height: 100%;
    z-index: 2;
    .wemeds-loading__img {
        width: 50px;
        height: 50px;
        content: url('../../images/wemeds-loading.gif');
    }
}