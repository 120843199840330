@import '../../App.scss';

.preferences-screen-container {
    height: calc(100vh - 80px - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 22px;
}
.preferences {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    padding-left: 20px;
}
.side-menu {
    height: 100%;
    width: 251px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-self: flex-start;
    overflow-y: auto;
    .side-menu__title {
        width: 136.5px;
        padding-bottom: 4px;
        border-bottom: solid 2px $pumpkin-orange;
        margin-bottom: 14px;
        .side-menu__title__text {
            font-family: 'AccordAlt';
            font-size: 24px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.67;
            letter-spacing: 0.4px;
            color: $greyish-brown;          
        }
    }
    .side-menu__items-title {
        font-family: 'Raleway';
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.39px;
        color: $dark-blue-grey;
        margin-top: 12px;
        margin-bottom: 13px;      
    }
}

.side-menu__item {
    width: 231px;
    border-radius: 4px;
    background-color: $white-four;  
    padding-left: 11px;
    padding-right: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .side-menu__item__left {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .side-menu__item__icon {
            width: 24px;
            height: 24px;
            object-fit: contain;
            margin-right: 11px;
        }
        .side-menu__item__icon--selected {
            filter: brightness(0) saturate(100%) invert(100%) sepia(3%) hue-rotate(285deg) brightness(108%) contrast(69%);

        }
        .side-menu__item__label {
            font-family: 'Raleway';
            font-size: 11px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.31px;
            color: $dark-blue-grey;          
        }
    }
    .side-menu__item__right {
        display: flex;
        flex-direction: row;
        .side-menu__item__arrow-icon {
            width: 14px;
            height: 14px;
            object-fit: contain;
            content: url('../../images/avancar-preto.svg')
        }
    }
}
.side-menu__item--selected {
    border: solid 1px $light-periwinkle;
    background-color: $white-three;
}

.preferences__content {
    height: 100%;
    width: calc(100% - 351px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: 110px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}