@import '../../App.scss';

.cid-screen-container {
    height: calc(100vh - 80px - 50px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 12px 0px 12px 0px;
}

.screen-title {
    font-family: 'AccordAlt';
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.53;
    letter-spacing: 0.5px;
    color: $greyish-brown;
    margin-bottom: 0px;
}


.card-content {
    width: 100%;
    padding-left: 13px;
    padding-top: 16px;
    padding-bottom: 19px;
    padding-right: 10px;
}
.card-content__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.card-content__column {
    display: flex;
    flex-direction: column;
}
.card-content__title {
    font-family: 'Raleway';
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $dark-blue-grey;  
    margin-bottom: 6px;
}
.card-content__subtitle {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $nice-blue;  
}
.arrow-right-img {
    width: 11px;
    height: 11px;
    object-fit: contain;
    content: url('../../images/avancar-laranja.svg');
}
.card-content__description {
    font-family: 'Raleway';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.65px;
    color: $brownish-grey;  
}

.cid-info {
    width: 254px;
    min-width: 254px;
    height: inherit;
    border-radius: 4px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    padding-top: 15px;
    padding-bottom: 15px;
    overflow-y: auto;
}
.cid-info__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding-bottom: 16px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);;
    padding-left: 20px;
    padding-right: 15px;
}
.cid-info__content {
    padding-left: 20px;
    padding-right: 15px;
    padding-top: 30px;
    position: relative;
}

.cid-info__font-img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    content: url('../../images/font-empty.svg');
    margin-right: 20px;
    cursor: pointer;
}
.cid-info__font-img:hover {
    width: 24px;
    height: 24px;
    object-fit: contain;
    content: url('../../images/font-gray.svg');
    margin-right: 20px;
    cursor: pointer;
}
.cid-info__font-img--orange {
    content: url('../../images/font-orange.svg');
}
.cid-info__font-img--orange:hover {
    content: url('../../images/font-orange.svg');
}
.cid-info__star-img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    content: url('../../images/empty-star.svg');
    cursor: pointer;
}
.cid-info__star-img:hover {
    width: 24px;
    height: 24px;
    object-fit: contain;
    content: url('../../images/gray-star.svg');
    cursor: pointer;
}
.cid-info__star-img--orange {
    content: url('../../images/orange-star.svg');
}
.cid-info__star-img--orange:hover {
    content: url('../../images/orange-star.svg');
}
.cid-info__code {
    font-family: 'AccordAlt';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.88;
    letter-spacing: normal;
    color: $nice-blue;
}
.cid-info__gender {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 26px;
    margin-top: 21px;
}
.cid-info__gender__title {
    font-family: 'AccordAlt';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.78;
    letter-spacing: normal;
    color: $greyish-brown;
    margin-right: 8px;
}
.cid-info__gender__male-img {
    width: 27px;
    height: 27px;
    object-fit: contain;
    content: url('../../images/male.svg');
}
.cid-info__gender__female-img {
    width: 30px;
    height: 30px;
    object-fit: contain;
    content: url('../../images/female.svg');
}
.cid-info__description {
    margin-bottom: 30px;
}
.cid-info__description__title {
    font-family: 'AccordAlt';
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.64;
    letter-spacing: normal;
    color: $greyish-brown;
    margin-bottom: 12px;
}
.cid-info__description__text {
    font-family: 'Raleway';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: 0.74px;
    color: $greyish-brown;
    margin-bottom: 0px;
}
.cid-info__classification {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}
.cid-info_classification__title {
    font-family: 'Raleway';
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.8px;
    color: $greyish-brown;
    margin-right: 8px;
}
.cid-info_classification__text {
    font-family: 'Raleway';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.8px;
    color: $greyish-brown;
}
.cid-info__deadly {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.cid-info__deadly__title {
    font-family: 'Raleway';
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.8px;
    color: $greyish-brown;
    margin-right: 8px;
}
.cid-info__deadly__label {
    width: 38px;
    height: 18px;
    border-radius: 4px;
    background-color: $deep-red;  
    display: flex;
    justify-content: center;
    align-items: center;
}
.cid-info__deadly__label--black {
    background-color: $black;  
}
.cid-info__deadly__text {
    font-family: 'AccordAlt';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: 0.53px;
    text-align: center;
    color: $white-three; 
}
.cid-info__slider-container {
    background-color: $white-three;
    width: 213px;
    height: 45px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    position: absolute;
    z-index: 1;  
    top: 0;
    right: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cid-info__slider {
    width: 133px!important;
    color: $very-light-pink-five!important;
}

.cid-info__favorite-success-toast {
    background-color: $maize-two;
    width: 213px;
    height: 45px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.28);
    position: absolute;
    z-index: 1;  
    top: 0;
    right: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-right: 16px;
    padding-left: 16px;
}

.cid-info__white-star-img {
    width: 19px;
    height: 18px;
    object-fit: contain;
    content: url('../../images/star-white.svg');
    margin-right: 8px;
}

.cid-info__favorite-success-msg {
    font-family: 'Raleway';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $greyish-brown;  
}

.MuiSlider-markActive {
    background-color: #3eb5b1!important;
    opacity: 1!important;
}