@import '../../App.scss';

.collapse {
    width: 100%;
    display: flex!important;
    flex-direction: column;
    cursor: pointer;
    .collapse__title {
        height: 48px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px $very-light-pink-four;
        .collapse__title__text {
            font-family: 'Raleway';
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $dark-blue-grey;
        }
        .collapse__open-icon {
            width: 11px;
            height: 12px;
            object-fit: contain;
            content: url('../../images/collapse_aberto.svg');
        }
        .collapse__closed-icon {
            width: 11px;
            height: 12px;
            object-fit: contain;
            content: url('../../images/collapse_fechado.svg');
        }
    }
    .collapse__content {
        padding-top: 8px;
        padding-bottom: 32px;
        border-bottom: solid 1px $very-light-pink-four;
        .collapse__content__text {
            font-family: 'Raleway';
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            color: $greyish-brown;
            margin: 0px;
        }
    }    
}