@import '../../App.scss';


.custom-toggle-button {
    width: 210px;
    height: 42px;
    border-radius: 25.5px;
    background-color: #F2F2F3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 0;
    padding: 2px;
    position: relative;
    cursor: pointer;
    .custom-toggle-button__white-oval {
        width: 103px;
        height: 42px;
        border-radius: 25.5px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18);
        background-color: #EF7D00;
        position: absolute;
        z-index: 1;
    }
    .custom-toggle-button__option {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 103px;
        z-index: 2;
        .custom-toggle-button__option__content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 110px;
        }
        .custom-toggle-button__option__dark-text {
            font-family: 'Raleway';
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            color: #FFFFFF;
        }
        .custom-toggle-button__option__white-text {
            font-family: 'Raleway';
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            color: #1A3C47;
        }
    }
}