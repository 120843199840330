@import '../../App.scss';

.input-icon-container {
    display: flex;
    width: 100%;
    margin-left: 10%;
    height: 34px;
    justify-content: space-between;
    align-items: center;
    background: #FAFAFA;
    border: 1px solid #E8EBEC;
    border-radius: 23px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.input-icon-magnifying-glass {
    content: url('../../images/icons/magnifying-glass.svg');
    height: 22px;
    width: 22px;
    margin-left: 12.5px;
}

.input-icon-x-circle {
    content: url('../../images/icons/x-circle.svg');
    height: 20px;
    width: 20px;
    margin-right: 12.5px;
    cursor: pointer;
}

.input-icon-input {
    border: 0px;
    background-color: transparent;
    width: 100%;
    caret-color: #EF7D00;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4A4A4A;

    &:focus-visible {
        outline: none;
    }
}