@import '../../App.scss';

/**************************************/
/* INPUTS */
/**************************************/
.search-icn {
    width: 20px;
    height: 20px;
    content: url('../../images/search.svg');
}
.clear-icn {
    width: 20px;
    height: 20px;
    content: url('../../images/excluir.svg');
    z-index: 1;
    cursor: pointer;
}

.auto-complete-container {
    .MuiFormControl-fullWidth {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
    .MuiFilledInput-input {
        padding-top: 19px !important;
        padding-bottom: 19px !important;

    }
    .MuiFilledInput-input:focus {
        padding-top: 19px !important;
        padding-bottom: 19px !important;
        border: none!important;
    }

    .MuiInputAdornment-filled {
        margin-top: 0 !important;
    }
    .MuiFilledInput-root:focus-within {
        background-color: $white-three!important;
    }
    .MuiFilledInput-adornedStart {
        border-top-left-radius: 0px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}