@import '../../App.scss';

.expired-hour-modal {
    z-index: 10000;
    position: fixed;
    right: 0px;
    bottom: 0px;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: $white-three;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.expired-hour-modal__full-content {
    width: 90vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
}
.close-btn {
    display: flex;
    align-items: flex-end;
    align-self: flex-end;
    margin-bottom: 59px;
    margin-top: 30px;
    cursor: pointer;
}
.close-btn__icon {
    width: 37px;
    height: 37px;
    object-fit: contain;
    content: url('../../images/fechar.svg');
}
.expired-hour-modal__content {
    width: 658px;
    max-width: 658px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}
.top-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 537px;
    .top-content__logo {
        width: 110px;
        height: 110px;
        border-radius: 55px;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        .top-content__logo__img_hour {
            width: 76px;
            height: 76px;
            content: url('../../images/hourglass.png');
        }
    }
}
.wemeds-logo-img {
    width: 56px;
    height: 56px;
    object-fit: contain;
    content: url('../../images/wemeds-logo-laranja.svg');
    margin-bottom: 21px;
}
.top-content__title {
    font-family: 'Raleway';
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: $dark-blue-grey;
    margin-bottom: 18px;
}
.top-content__subtitle {
    font-family: 'Raleway';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: $greyish-brown;  
}

.middle-banner {
    margin-top: 34px;
    padding-bottom: 50px;
    border-bottom: solid 1px $very-light-pink-four;
    margin-bottom: 34px;
}
.middle-banner__img {
    width: 657px;
    height: 100px;
    object-fit: contain;
    content: url('../../images/banner-conteudo-exclusivo.svg');
    cursor: pointer;
}
.bottom-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 487px;
} 
.bottom-content__text-container {
    text-align: center;
}
.bottom-content__text {
    font-family: 'Raleway';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: $dark-blue-grey;
}
.bottom-content__text--bold {
    font-weight: 600;
}
.bottom-content__btn-container {
    margin-top: 23px;
}
.button-icon {
    width: 25px;
    height: 22px;
    object-fit: contain;
    content: url('../../images/bacterium-branco.svg');
}