@import '../../App.scss';

.dose-main-container {
    height: calc(100vh - 80px - 50px);
    display: flex;
    padding-top: 55px;
    justify-content: center;
}

.screen-company-content-container {
    height: 100%;
    width: 70vw;
    padding-top: 12px;
    margin-left: 60px;
}