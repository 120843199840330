@import '../../App';

.dose-main-container {
  height: calc(100vh - 80px - 50px);
  display: flex;
  padding-top: 55px;
  justify-content: center;
}

.check-on-img {
  content: url('../../images/flashcard/check-on.png');
}

.check-off-img {
  content: url('../../images/flashcard/check-off.png');
}

.orange-close-eye-img {
  content: url('../../images/flashcard/orange-close-eye.svg');
}

.orange-play-img {
  content: url('../../images/flashcard/orange-play.png');
}

.pencil-icon-img {
  content: url('../../images/flashcard/pencil-icon.png');
}

.blue-arrow-down-img {
  content: url('../../images/flashcard/blue-arrow-down.png');
}

.radio-on-img {
  content: url('../../images/flashcard/radio-on.png');
}

.radio-off-img {
  content: url('../../images/flashcard/radio-off.png');
}

.orange-open-eye-img {
  content: url('../../images/flashcard/orange-open-eye.svg');
}

.star-img {
  content: url('../../images/flashcard/star.svg');
}

.white-yellow-star-img {
  content: url('../../images/flashcard/white-yellow-star.svg');
}

.yellow-full-star-img {
  content: url('../../images/flashcard/yellow-full-star.svg');
}

.white-shuffle-img {
  content: url('../../images/flashcard/white-shuffle.svg');
}

.black-caret-left-img {
  content: url('../../images/flashcard/black-caret-left.svg');
}

.black-list-dashes-img {
  content: url('../../images/flashcard/black-list-dashes.svg');
}

.white-list-dashes-img {
  content: url('../../images/flashcard/white-list-dashes.svg');
}

.shuffle-img {
  content: url('../../images/flashcard/shuffle.svg');
}

.red-flag-img {
  content: url('../../images/flashcard/red-flag.svg');
}

.chart-line-up-img {
  content: url('../../images/flashcard/chart-line-up.svg');
}

.plus-circle-img {
  content: url('../../images/flashcard/plus-circle.svg');
}

.arrow-up-right-blue-img {
  content: url('../../images/flashcard/arrow-up-right-blue.svg');
}

.grey-star-img {
  content: url('../../images/flashcard/grey-star.svg');
}

.orange-clock-afternoon-img {
  content: url('../../images/flashcard/orange-clock-afternoon.svg');
}

.orange-flashcards-img {
  content: url('../../images/flashcard/orange-flashcards.svg');
}

.orange-pencil-line-img {
  content: url('../../images/flashcard/orange-pencil-line.svg');
}

.orange-timer-img {
  content: url('../../images/flashcard/orange-timer.svg');
}

.grey-arrow-img {
  content: url('../../images/flashcard/grey-arrow-down.svg');

  &-down {
    @extend .grey-arrow-img;
  }

  &-up {
    @extend .grey-arrow-img;
    transform: rotateX(160deg);
  }
}

.arrow-right-orange-img {
  content: url('../../images/flashcard/caret-right-orange.svg');
  cursor: pointer;

  &-left {
    @extend .arrow-right-orange-img;
    transform: rotateY(160deg);
  }

  &-right {
    @extend .arrow-right-orange-img;
  }
}

.disabled-arrow {
  filter: brightness(0) saturate(100%) invert(77%) sepia(8%) saturate(363%) hue-rotate(147deg) brightness(93%) contrast(80%);
}

.flashcard-run-card-container {
  background-color: #ffffff;
}

.answer-button-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: 14vw;
  margin-right: 5px;
  margin-left: 5px;
  height: 48px;
  padding: 12px 8px 12px 8px;
  cursor: pointer;
  border-radius: 32px;

  &--1 {
    @extend .answer-button-container;
    background-color: transparent;
    border: 1px solid #c25576;
  }

  &--2 {
    @extend .answer-button-container;
    background-color: transparent;
    border: 1px solid #f59e24;
  }

  &--3 {
    @extend .answer-button-container;
    background-color: #2572B6;
    border: 1px solid #2572B6;
  }
}

.answer-button-text {
  flex-grow: 1;
  flex-shrink: 0;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;

  &--1 {
    @extend .answer-button-text;
    color: #c25576;
  }

  &--2 {
    @extend .answer-button-text;
    color: #f59e24;
  }

  &--3 {
    @extend .answer-button-text;
    color: #ffffff;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.flashard-question-text {
  font-family: Raleway, var(--default-font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: #404e52;
}

.flashcard-content {
  overflow-y: auto;
  max-height: 25vh;
  padding-left: 10%;
  padding-right: 10%;
}

.flashcard-content-full {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
}

.flashcard-markdown {
  margin-left: 26px;
  width: auto;
  padding-top: 10px;

  hr {
    margin-top: 0px;
  }

  ul li {
    margin-bottom: 12px;
  }

  .flashcard-markdown__paragraph {
    font-family: 'Raleway';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.5px;
    color: $greyish-brown;
    margin-bottom: 12px;

    ul li {
      margin-bottom: 0px;
    }
  }

  .flashcard-markdown__strong {
    font-weight: bold;
  }

  .flashcard-markdown__highlighted {
    background-color: yellow;
  }

  .flashcard-markdown__heading {
    font-family: 'Raleway';
    font-size: 18px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: 0.5px;
    color: $dark-blue-grey;
  }

  .flashcard-markdown__heading span {
    font-weight: 600;
  }

  .flashcard-markdown__emphasis {
    font-family: 'Raleway';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.57;
    letter-spacing: 0.5px;
    color: $greyish-brown;
    margin-bottom: 0px;
  }

  .flashcard-markdown__link {
    font-family: 'Raleway';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: 0.5px;
    color: $dusty-orange;
    margin-bottom: 0px;
  }

  .flashcard-markdown__list-item {
    font-family: 'Raleway';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.5px;
    color: $greyish-brown;
  }

  .flashcard-markdown__highlight {
    background-color: yellow;
  }

  .flashcard-markdown__image-container {
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
    justify-content: center;

    .flashcard-markdown__image {
      object-fit: contain;
      max-height: 250px;
      max-width: 18vw;
      border-radius: 10px;
      z-index: 1;
    }

    .flashcard-markdown__image__zoom-icon {
      opacity: 1 !important;
      width: 32px;
      height: 32px;
      bottom: 10px;
      object-fit: contain;
      content: url('../../images/flashcard/white-magnifying-glass.svg');
      position: absolute;
      align-self: center;
      z-index: 2;
    }
  }

  .flashcard-markdown__code {
    font-family: 'Raleway';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 0.5px;
    color: $greyish-brown;
    padding: 8px;
  }

  .flashcard-markdown__blockquote {
    display: flex;
    justify-content: center;
    width: 100%;

    .flashcard-markdown__blockquote__content {
      width: 40%;
    }
  }

  .flashcard-markdown__blockquote div {
    font-family: 'Raleway';
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.36px;
    color: $brown-grey-three;
  }

  .flashcard-markdown__blockquote div a {
    color: $tangerine-two;
  }

}

.frame-6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  width: 50%;
  height: 100%;
}

.frame-15 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons-cta {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 228px;
  height: 48px;
  cursor: pointer;
  margin-top: 5vh;
  background: transparent;
  border: 1px solid #ef7d00;
  border-radius: 32px;
}

.reset-statistics {
  color: #ef7d00;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
}

.font-resize-img {
  width: 32px;
  height: 32px;
  object-fit: contain;
  content: url('../../images/font-gray.svg');
  cursor: pointer;
}

.font-resize-img:hover {
  width: 32px;
  height: 32px;
  object-fit: contain;
  content: url('../../images/font-dark-gray.svg');
  cursor: pointer;
}

.font-resize-img--orange {
  content: url('../../images/font-orange.svg');
}

.font-resize-img--orange:hover {
  content: url('../../images/font-orange.svg');
}

.green-percentage-circle-img {
  content: url('../../images/flashcard/green-percentage-circle.png');
}

.rocket-img {
  content: url('../../images/flashcard/rocket.png');
}

.dots-three-vertical-img {
  content: url('../../images/flashcard/dots-three-vertical.svg');
}

.orange-percentage-circle-img {
  content: url('../../images/flashcard/orange-percentage-circle.png');
}

.blocked-padlock-img {
  content: url('../../images/flashcard/blocked-padlock.png');
}

.orange-blocked-padlock-img {
  content: url('../../images/flashcard/blocked-padlock.png');
  filter: invert(54%) sepia(20%) saturate(6314%) hue-rotate(5deg) brightness(99%) contrast(101%)
}

.toast-text {
  color: white;
  font-size: 14px;
  font-family: Raleway;
  font-weight: 600;
}

.favorite-empty-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 40%;
}

.my-flashcard-text-area {
  color: #414E52;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  min-height: 56px;
  text-align: center;
  resize: none;
  border: transparent 1px solid;
  &:focus {
    border: transparent 1px solid;
    outline: none !important;
  }
  &:active {
    border: transparent 1px solid;
  }
}

.my-flashcard-text-area::placeholder {
  color: #A2AFB3;
}

.my-flashcard-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 85%;
}