@import '../../App.scss';

.card-item_content {
    border-radius: 0px !important; 
    box-shadow: 0 0 0px 0 transparent !important;
    background-color: transparent !important;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    z-index: 1;
}