@import '../../App.scss';

.premium-header {
    width: 100%;
    height: 50px;
    border-bottom: solid 1px $white-four;
    background-color: $white-four;  
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .premium-header__content {
        display: flex;
        flex-direction: row;
        .premium-header__price-icon {
            width: 35px;
            height: 25px;
            object-fit: contain;
            content: url('../../images/icon-tag.svg');
            margin-right: 8px;
            margin-bottom: -3px;
        }
        .premium-header__wemeds-img {
            width: 77px;
            height: 16px;
            object-fit: contain;
            content: url('../../images/wemeds-text.svg');
            margin-right: 6px;
            margin-bottom: 6px;
        }
        .premium-header__premium-img {
            width: 80px;
            height: 21px;
            object-fit: contain;
            content: url('../../images/premium-text.svg');
            margin-right: 8px;
        }
        .premium-header__text {
            font-family: 'AccordAlt';
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.75;
            letter-spacing: 0.31px;
            color: $greyish-brown; 
            margin-right: 4px;         
        }
        .premium-header__text-orange {
            font-family: 'AccordAlt';
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.75;
            letter-spacing: 0.31px;
            color: $pumpkin;
            text-decoration: underline;
            cursor: pointer;     
        }
    }
}