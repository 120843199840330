@import '../../App.scss';

.register-subscription {
    height: 100vh;
    overflow: auto;
    padding-bottom: 12px;
    @include responsive(s) {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
    }
    .register-subscription__header {
        height: 80px;
        width: 100%;
        border-bottom: solid 1px $light-periwinkle;
        display: flex;
        flex-direction: row;
        align-items: center;
        @include responsive(s) {
            height: 67px;
            display: flex;
            justify-content: center;
        }
        .register-subscription__header__logo {
            cursor: pointer;
            position: relative;
            margin-top: 20px;
            margin-left: 15%;
            margin-bottom: 20px;
            width: 35px;
            height: 45px;
            content: url('../../images/logo@3x.png');
            @include responsive(s) {
                position: unset;
                width: 30px;
                height: 38px;
                margin-top: 15px;
                margin-bottom: 15px;
                margin-left: 0px;
            }
        }
    }
}

.steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .steps__header {
        position: absolute;
        top: -34px;
        z-index: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        @include responsive(s) {
            display: none;
        }
        .steps__header__step {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            margin-right: 21px;
            .steps__header__step__title {
                font-family: 'Raleway';
                font-size: 11px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.46px;
                color: $very-light-pink;
                width: 77px;
                text-align: center;
            }
            .steps__header__step__title--active {
                color: $dark-blue-grey;
            }
            .steps__header__step__icon-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                position: absolute;
                height: 23px;
                top: 22px;
                .steps__header__step__icon-active {
                    width: 25px;
                    height: 25px;
                    content: url('../../images/step-progress.svg');
                }

                .steps__header__step__icon-done {
                    width: 25px;
                    height: 25px;
                    content: url('../../images/progress-finish.svg');
                }

                .steps__header__step__icon-inactive {
                    width: 13px;
                    height: 13px;
                    content: url('../../images/progress-none.svg');
                }
                .steps__header__step__line {
                    width: 99px;
                    height: 1px;
                    border: solid 1px $light-periwinkle;
                    position: absolute;
                    z-index: -1;
                    left: 11px;
                }
                .steps__header__step__line--active {
                    border: solid 1px $marine;
                    background-color: $marine;
                }
            }
        }
    }
    .steps__content {
        padding-top: 40px;
        width: 100%;
        @include responsive(s) {
            padding-top: 28px;
            padding-left: 8px;
            padding-right: 8px;
        }
    }
    .steps__footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 50px;
        justify-content: center;
        .steps__footer__back-icon {
            width: 16px;
            height: 16px;
            object-fit: contain;
            content: url('../../images/voltar-gray.svg');
        }
        .steps__footer__advance-icon {
            width: 16px;
            height: 16px;
            object-fit: contain;
            content: url('../../images/avancar-white.svg');
        }
    }
}
.premium-logo {
    margin-bottom: 25px;
    width: 300px;
    height: 37px;
    object-fit: contain;
    content: url('../../images/wemeds-premium.svg');
    @include responsive(s) {
        margin: auto;
        margin-bottom: 22px;
        width: 240px;
    }
}

.premium-logo-payment {
    display: none;
    @include responsive(s) {
        display: block;
        margin-bottom: 22px;
        width: 240px;
    }
}

.login-step__inputs-mobile {
    display: none;
    @include responsive(s) {
        display: flex;
        justify-content: space-between;
        margin-top: 18px;
        margin-bottom: 15px;
        letter-spacing: 0.67px;

        &--icon-active {
            content: url('../../images/step-progress.svg');
        }

        &--text {
            font-size: 1rem;
            text-transform: uppercase;
            padding-left: 11px;
        }

        &--icon-arrow {
            content: url('../../images/avancar-gray.svg');
        }
    }
}

.login-step__inputs-mobile-done {
    display: none;
    @include responsive(s) {
        letter-spacing: 0.67px;
        width: 100vw;
        display: flex;
        justify-content: space-between;
        border-bottom: solid 1px $ice-blue;
        padding: 18px 0 18px 0;

        &--icon-done {
            content: url('../../images/progress-finish.svg');
        }

        &--text {
            font-size: 1rem;
            text-transform: uppercase;
            padding-left: 11px;
        }

        &--edit {
            font-size: 0.75rem;
            color: $pumpkin;
            margin-right: 22px;
        }

        &--icon-arrow {
            content: url('../../images/avancar-gray.svg');
        }
    }
}

.login-step__inputs-mobile-disabled--all {
    width: 100vw;
    margin-top: 30px;
    .login-step__inputs-mobile-disabled {
        display: none;
        @include responsive(s) {
            display: flex;
            justify-content: space-between;
            border-top: solid 1px $ice-blue;
            padding: 18px 0 18px 0;

            &--text {
                font-size: 1rem;
                text-transform: uppercase;
                padding-left: 57px;
                color: $bluey-grey;
                letter-spacing: 0.67px;
            }

            &--icon-arrow {
                content: url('../../images/avancar-gray.svg');
                padding-right: 23px;
            }
        }
    }
}

.login-step {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    .login-step__inputs {
        // width: 100%;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
    }
}
.address-step {
    display: flex;
    flex-direction: column;
    align-items: center;

    .PrivateSwitchBase-root-404,
    .PrivateSwitchBase-root-73,
    .PrivateSwitchBase-root-81 {
        padding: 0;
        margin-right: 8px;
    }

    .address-step__inputs {
        width: 100%;
        // display: flex;
        // flex-direction: column;
        .address-step__checkbox {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left;
            margin-top: 46px;

            span {
                line-height: 18px;
            }

            .address-step__checkbox__label {
                font-family: 'Raleway';
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: 0.27px;
                color: $brownish-grey;
            }
            .address-step__checkbox__label--orange {
                color: $pumpkin;
                cursor: pointer;
                font-size: 14px;
            }
        }
    }
}

.payment-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    .payment-step__inputs {
        width: 100%;
        @include responsive (s) {
            margin-top: 40px;
        }
        .MuiInputLabel-root {
            @include responsive (s) {
                font-size: 12px;
            }
        }
        .custom-dropdown__select__text {
            @include responsive (s) {
                font-size: 12px;
            }
        }
    }
    .payment-step__banner {
        border-radius: 8px;
        border: 1px solid $very-light-pink-seven;
        margin-bottom: 25px;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        .payment-step__banner__annual-plan {
            position: absolute;
            top: -4px;
            left: 5px; 
            .payment-step__banner__annual-plan__text {
                font-family: 'AccordAlt';
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.13;
                letter-spacing: 0.31px;
                color: $greyish-brown;
                margin: 0;
            }
        }
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 20px;
        padding-top: 0;
        @include responsive (s){
            padding: 0 20px 20px 17px
        }

        .payment-step__banner__premium {
            width: 139px;
            height: 61px;
            border-radius: 8px;
            background-color: $white-four;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            .payment-step__banner__premium__img-1 {
                width: 104px;
                height: 22px;
                object-fit: contain;
                content: url('../../images/wemeds-text.svg');
            }
            .payment-step__banner__premium__img-2 {
                width: 104px;
                height: 27px;
                object-fit: contain;
                content: url('../../images/premium-text.svg');
                filter: brightness(0) saturate(100%) invert(54%) sepia(65%) saturate(423%) hue-rotate(15deg) brightness(96%) contrast(90%);
            }
        }
        .payment-step__banner__prices {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;
            @media  screen and ( max-width: 1198px) {
                order: 3;
                
            }

            .payment-step__banner__prices__title-1 {
                font-family: 'AccordAlt';
                font-size: 18px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.13;
                letter-spacing: 0.31px;
                color: $greyish-brown;
            }
            .payment-step__banner__prices__title-2 {
                font-family: 'AccordAlt';
                font-size: 24px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.13;
                letter-spacing: 0.31px;
                color: $greyish-brown;
            }
            .payment-step__banner__prices__title-3 {
                font-family: 'AccordAlt';
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.13;
                letter-spacing: 0.31px;
                color: $brownish-grey;
            }
        }
    }
    .payment-step__banner__flag {
        width: 95px;
        height: 78px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media  screen and ( max-width: 766px) {
            order: 3;               
        }

        @media  screen and ( max-width: 543px) {
            order: 2;               
        }

        .payment-step__banner__flag__background-img {
            width: 95px;
            height: 78px;
            object-fit: contain;
            content: url('../../images/flag.svg');
            position: absolute;
            z-index: -1;
            margin-top: -3px;
            @include responsive (ss) {
                margin-top: 0;
            }
        }
        .payment-step__banner__flag__text-1 {
            font-family: 'AccordAlt';
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.51;
            letter-spacing: 0.1px;
            text-align: center;
            color: $white-three;
            @include responsive (ss) {
                margin-top: 5px;
            }
        }
        .payment-step__banner__flag__text-2 {
            font-family: 'AccordAlt';
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $white-three;
        }
        .payment-step__banner__flag__text-3 {
            font-family: 'AccordAlt';
            font-size: 26px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 0.19px;
            color: $white-three;
        }
        .payment-step__banner__flag__text-4 {
            font-family: 'AccordAlt';
            font-size: 11px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $white-three;
        }
    }
    .payment-step__coupon {
        border-radius: 8px;
        border: dotted 2px $mango;
        background-color: $white-three;
        padding-top: 17px;
        padding-bottom: 19px;
        padding-left: 38px;
        padding-right: 29px;
        @include responsive (s) {
            padding-left: 17px;
            padding-right: 20px;
        }

        .payment-step__coupon__title {
            font-family: 'AccordAlt';
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.13;
            letter-spacing: 0.31px;
            color: $greyish-brown;
            margin-bottom: 7px;
        }
        .payment-step__coupon__content {
            display: flex;
            flex-direction: row;
            align-items: center;
            .payment-step__coupon__input {
            }
            .payment-step__coupon__input:focus {
                outline: none;
            }
            .payment-step__coupon__input::placeholder {
                font-family: 'Raleway';
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                color: $bluey-grey;
            }
            .payment-step__coupon__arrow-icon {
                width: 11px;
                height: 14px;
                object-fit: contain;
                content: url('../../images/flechas.svg');
                margin-left: 13px;
                margin-right: 11px;
            }
            .payment-step__coupon__result {
                width: 100%;
                height: 56px;
                border-radius: 6px;
                border: solid 1px $ice-blue;
                background-color: $white-three;
                padding-left: 16px;
                padding-top: 3px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .payment-step__coupon__result__success-title {
                    font-family: 'AccordAlt';
                    font-size: 12px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.83;
                    letter-spacing: 0.23px;
                    color: $greyish-brown;
                    margin-bottom: 0px;
                }
                .payment-step__coupon__result__success-value-extension {
                    font-family: 'AccordAlt';
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.83;
                    font-size: 16px;
                    letter-spacing: 0.31px;
                    color: $mango;
                }
                .payment-step__coupon__result__success-value {
                    font-family: 'AccordAlt';
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.83;
                    font-size: 20px;
                    letter-spacing: 0.38px;
                    color: $mango;
                }
            }
        }
    }
    .payment-finish {
        display: flex;
        flex-direction: column;
        align-self: center;
        width: 100%;
        .payment-finish__title {
            font-family: 'AccordAlt';
            font-size: 1.625rem;
            font-weight: 500;
            letter-spacing: 0.5px;
            color: $dark-blue-grey;
            line-height: 1.2;
            margin-bottom: 36px;
            @include responsive (s) {
                text-align: center;
                font-size: 1.25rem;
            }
        }
        .payment-finish__card {
            border-radius: 8px;
            background-color: $white-three;
            .payment-finish__card__header {
                width: 100%;
                height: 170px;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                // border-radius: 8px;
                background-image: linear-gradient(to left, $maize 105%, $mango -8%);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                padding-top: 20px;
                .payment-finish__card__header__coupon-icon {
                    width: 59px;
                    height: 43px;
                    object-fit: contain;
                    content: url('../../images/icon-tag.svg');
                    position: absolute;
                    top: -20px;
                }
                .payment-finish__card__header__title-1 {
                    font-family: 'AccordAlt';
                    font-size: 24px;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: $dark-blue-grey;
                    font-weight: 500;
                }
                .payment-finish__card__header__title-2 {
                    font-family: 'AccordAlt';
                    font-size: 24px;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: $dark-blue-grey;
                    font-weight: bold;
                }
                .payment-finish__card__header__title-3 {
                    font-family: 'AccordAlt';
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: $dark-blue-grey;
                    font-size: 18px;
                    font-weight: 800;
                }
                .payment-finish__card__header__title-3--white {
                    font-size: 19px;
                    letter-spacing: 0.37px;
                    color: $white-three;
                }
                .payment-finish__card__header__logo {
                    width: 193px;
                    height: 33px;
                    object-fit: contain;
                    content: url('../../images/premium-logo.svg');
                }
                .payment-finish__card__header__line {
                    width: 193px;
                    height: 1px;
                    background-color: $white-three;
                    margin: 6px 0px 9px 0px;
                }
            }
            .payment-finish__card__content {
                width: 100%;
                border: dashed 2px $mango;
                border-top: none;
                padding: 22px 22px 22px 22px;
                .payment-finish__card__content__checkboxes {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-bottom: 5px;
                    .payment-finish__card__content__checkbox {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        .payment-finish__card__content__checkbox__label {
                            font-family: 'Raleway';
                            font-size: 16px;
                            font-weight: 500;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: 0.31px;
                            color: $greyish-brown;
                        }
                    }
                }
                .payment-finish__card__content__details {
                    margin-top: 20px;
                    .payment-finish__card__content__details__title {
                        font-family: 'Raleway';
                        font-size: 12px;
                        font-weight: 600;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 0.23px;
                        color: $greyish-brown;
                        margin-bottom: 18px;
                    }
                    .payment-finish__card__content__details__row {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        .payment-finish__card__content__details__row__label {
                            font-family: 'AccordAlt';
                            font-size: 14px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 2.07;
                            letter-spacing: 0.27px;
                            color: $brownish-grey;
                        }
                        .payment-finish__card__content__details__row__line {
                            flex-grow: 1;
                            height: 0px;
                            border: dashed 1px $brownish-grey;
                            margin-right: 26px;
                            margin-left: 27px;
                        }
                    }
                    .payment-finish__card__content__details__total {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        margin: 17px 0px 3px 0px;
                        .payment-finish__card__content__details__total__title {
                            font-family: 'Raleway';
                            font-size: 12px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: 0.23px;
                            color: $greyish-brown;
                            margin-bottom: 0px;
                        }
                        .payment-finish__card__content__details__total__from-text {
                            font-family: 'AccordAlt';
                            font-size: 12px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 2.33;
                            letter-spacing: 0.23px;
                            text-align: right;
                            color: $brownish-grey;
                        }
                        .payment-finish__card__content__details__total__separator {
                            width: 1px;
                            height: 12px;
                            background-color: $very-light-pink;
                            margin: 0px 8px 0px 12px;
                        }
                        .payment-finish__card__content__details__total__value {
                            font-family: 'AccordAlt';
                            font-size: 20px;
                            font-weight: 500;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.4;
                            letter-spacing: 0.38px;
                            color: $greyish-teal;
                            margin-left: 7px;
                        }
                        .payment-finish__card__content__details__total__subtitle {
                            font-family: 'AccordAlt';
                            font-size: 10px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            letter-spacing: 0.19px;
                            color: $brownish-grey;
                        }
                    }
                }
                .payment-finish__card__content__installments {
                    margin: 39px 0px 17px 0px;
                    .payment-finish__card__content__installments__title {
                        font-family: 'Raleway';
                        font-size: 12px;
                        font-weight: 600;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 0.23px;
                        color: $greyish-brown;
                        margin: 0px 0px 10px 0px;
                    }
                }

                .payment-finish__card__content__line {
                    width: 100%;
                    height: 1px;
                    background-color: $very-light-pink-four;
                }
            }
        }
    }
}

.finish-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    .finish-step__line {
        width: 100%;
        height: 1px;
        background-color: $very-light-pink-four;
    }
    .finish-step__success {
        display: flex;
        flex-direction: column;
        align-items: center;
        .finish-step__success__icon {
            width: 69px;
            height: 69px;
            object-fit: contain;
            content: url('../../images/sucesso.svg');
            margin-bottom: 38px;
        }
        .finish-step__success__title {
            font-family: 'AccordAlt';
            font-size: 26px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            text-align: center;
            color: $dark-blue-grey;
            margin: 0px 0px 15px 0px;
        }
        .finish-step__success__subtitle {
            font-family: 'Raleway';
            font-size: 18px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.35px;
            text-align: center;
            margin: 0px 0px 22px 0px;
        }
    }
    .finish-step__details {
        margin-top: 20px;
        .finish-step__details__title {
            font-family: 'Raleway';
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.23px;
            color: $greyish-brown;
            margin-bottom: 18px;
        }
        .finish-step__details__row {
            display: flex;
            flex-direction: row;
            align-items: center;
            .finish-step__details__row__label {
                font-family: 'AccordAlt';
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.07;
                letter-spacing: 0.27px;
                color: $brownish-grey;
            }
            .finish-step__details__row__label--big {
                font-family: 'AccordAlt';
                font-size: 20px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.4;
                letter-spacing: 0.38px;
                color: $greyish-teal;
            }
            .finish-step__details__row__line {
                flex-grow: 1;
                height: 0px;
                border: dashed 1px $brownish-grey;
                margin-right: 26px;
                margin-left: 27px;
            }
        }
    }
    .finish-step__email-confirmation {
        margin-top: 29px;
    }
}

.register-subscription__checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 9px 0px 9px 0px;
    .register-subscription__checkbox {
        width: 26px;
        height: 26px;
        border-radius: 4px;
        border: solid 1px $ice-blue;
        background-color: $white-two;
        padding: 3px;
        cursor: pointer;
    }
    .register-subscription__checkbox--active {
        border: solid 1px $greyish-teal-two;
        background-color: $white-three;
    }
    .register-subscription__checkbox--active-icon {
        width: 18px;
        height: 18px;
        object-fit: contain;
        content: url('../../images/confirm.svg');
    }
    .register-subscription__checkbox__label {
        font-family: 'Raleway';
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.27px;
        color: $brownish-grey;
        margin-left: 10px;
    }
    .register-subscription__checkbox__label--active {
        font-weight: 600;
    }
}

.register-subscription__note {
    max-width: 231px;
    min-height: 71px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px $light-periwinkle;
    background-color: $white-three;
    padding: 6px 6px 6px 20px;
    display: flex;
    flex-direction: row;

    span {
        margin-bottom: 12px;
    }
    @include responsive(s) {
        display: none;
    }
    .register-subscription__note__text {
        font-family: 'Raleway';
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: 0.41px;
        color: $greyish-brown;
        margin-top: 13px;
    }
    .register-subscription__note__text--bold {
        font-weight: 600;
    }
    .register-subscription__note__close-icon {
        width: 18px;
        height: 18px;
        content: url('../../images/fechar-2.svg');
        margin-left: 8px;
        cursor: pointer;
    }
}

.mobile-note-container {
    display: none;
    @include responsive(s) {
        display: block;
    }
}

.street_row_container {
    margin-top: -35px !important;
    @include responsive(s) {
        margin-top: 20px !important;
    }
}

.register-subscription__mobile-spacing {
    @include responsive(s) {
        margin-top: 25px;
    }
}

.mobile-address-note {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    span {
        line-height: 18px;
    }

    .mobile-address-note__label {
        font-family: 'Raleway';
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.27px;
        color: $brownish-grey;
    }
}