@import '../../App.scss';

.diagnapp-screen {
    height: max-content;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 36px;
    padding-bottom: 36px;
    min-height: calc(100vh - 80px - 50px);
    .diagnapp-screen__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 20px;
        position: relative;
    }
}

.diagnapp-carousel {
    display: flex;
    flex-direction: column;
    width: 487px;
    .diagnapp-carousel__card {
        width: 487px;
        min-height: 323px;
        height: auto;
        border-radius: 4px;
        box-shadow: 0 0 4px 0 $very-light-pink-seven;
        background-color: $white-three;
    }
    .diagnappp-carousel__footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 21px;
        .diagnappp-carousel__indicators {
            display: flex;
            flex-direction: row;
            align-items: center;
            .diagnappp-carousel__indicator {
                width: 8px;
                height: 8px;
                border-radius: 4px;
                background-color: $light-periwinkle;
                margin-right: 10px;
            }
            .diagnappp-carousel__indicator--active {
                width: 10px;
                height: 10px;
                border-radius: 5px;
                background-color: $mango;
            }
        }
        .diagnapp-carousel__back-icon {
            width: 16px;
            height: 16px;
            object-fit: contain;
            content: url('../../images/voltar-gray.svg');
        }
        .diagnapp-carousel__advance-icon {
            width: 16px;
            height: 16px;
            object-fit: contain;      
            content: url('../../images/avancar-white.svg');
        }
    }
}

.age-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    .age-component__title {
        font-family: 'AccordAlt';
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: $dark-blue-grey;
        margin: 0px;
        margin-bottom: 22px;
    }
    .age-component__input-container {
        width: 157px;
        height: 56px;
        border-radius: 4px;
        border: solid 1px #d9d9d9;
        background-color: $white-three;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 7px;
        .age-component__input {
            width: 66px;
            font-family: 'Raleway';
            font-size: 38px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 38px;
            letter-spacing: normal;
            text-align: right;
            color: $dark-blue-grey;
            border: none;
        }
        .age-component__input:focus {
            outline: none;
        }
        .age-component__input-label {
            font-family: 'Raleway';
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.78;
            letter-spacing: normal;
            color: $brown-grey;
            margin: 0px;
            margin-left: 4px;
            margin-bottom: 4px;
        }
    }
    .age-component__input-title {
        font-family: 'Raleway';
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        color: $dark-blue-grey;
        margin: 0px;
        margin-top: 30px;
        margin-bottom: 11px;
    }
    .age-component__checkbox {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 31px;
        .age-component__checkbox__label {
            font-family: 'Raleway';
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.08;
            letter-spacing: normal;
            color: $greyish-brown;
        }
    }
}

.gender-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    .gender-component__title {
        font-family: 'AccordAlt';
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: $dark-blue-grey;
        margin: 0px;
        margin-bottom: 22px;
    }
    .gender-component__pregnant {
        margin-top: 26px;
    }
}
.ethnicity-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    .ethnicity-component__title {
        font-family: 'AccordAlt';
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: $dark-blue-grey;
        margin: 0px;
        margin-bottom: 22px;
    }
    .ethnicity-component__radio {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
.region-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    .region-component__title {
        font-family: 'AccordAlt';
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: $dark-blue-grey;
        margin: 0px;
        margin-bottom: 22px;
    }
    .region-component__radio {
        margin-top: 26px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
.risk-factor-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    .risk-factor-component__title {
        font-family: 'AccordAlt';
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: $dark-blue-grey;
        margin: 0px;
        margin-bottom: 10px;
    }
    .risk-factor-component__subtitle {
        width: 327px;
        font-family: 'Raleway';
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: $purplish-grey;
    }
    .risk-factor-component__dropdowns {
        margin-top: 21px;
    }
}
.symptom-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    .symptom-component__title {
        font-family: 'AccordAlt';
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: $dark-blue-grey;
        margin: 0px;
        margin-bottom: 10px;
    }
    .symptom-component__subtitle {
        width: 327px;
        font-family: 'Raleway';
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: $purplish-grey;
    }
    .symptom-component__dropdowns {
        margin-top: 21px;
    }
}
.success-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 38px;
    .success-component__title {
        font-family: 'AccordAlt';
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: $dark-blue-grey;
        margin: 0px;
        margin-bottom: 10px;
        margin-top: 12px;
    }
    .success-component__subtitle {
        width: 327px;
        font-family: 'Raleway';
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: $purplish-grey;
        margin-bottom: 37px;
    }
    .success-component__icon {
        width: 99px;
        height: 109px;
        object-fit: contain;
        content: url('../../images/diagnapp-success.svg');
    }
}

.loading {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    .loading__background {
        background-color: white;
        opacity: 0.6;
        top: 30px;
        width: 500px;
        height: 500px;
        z-index: 2;
    }
    .loading-img {
        width: 50px;
        height: 50px;
        content: url('../../images/wemeds-loading.gif');
        z-index: 3;
        position: absolute;
        top: 175px;
    }
}

.diagnapp-results {
    display: flex;
    .diagnapp-results__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .diagnapp-results__result {
            height: 54px;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 662px;
            position: relative;
            margin-bottom: 11px;
            cursor: pointer;
            .diagnapp-results__result__content {
                width: 100%;
                height: 54px;
                border-radius: 4px;
                box-shadow: 0 0 4px 0 $beige;
                background-color: $white-three;    
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-left: 20px;
                padding-right: 17px;
                .diagnapp-results__result__label {
                    font-family: 'Raleway';
                    font-size: 14px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.7px;
                    color: $charcoal-grey;
                    margin: 0px;
                }
                .diagnapp-results__result__arrow-icon {
                    width: 16px;
                    height: 16px;
                    object-fit: contain;
                    content: url('../../images/avancar-laranja.svg');
                }
            }
            .diagnapp-results__result__weight-indicator {
                height: 50px;
                min-width: 10px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }
    .diagnapp-results__empty {
        display: flex;
    }
}