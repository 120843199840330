@import '../../App.scss';

.main-container {
    display: flex;
    justify-content: center;
    overflow: auto;
    padding-bottom: 80px;
}
.subscription-banner-img {
    height: 690px;
    width: 230px;
    content: url('../../images/subscription-banner.png');
    margin-right: 26px;
    cursor: pointer;
}
.categories-background {
    width: 100%;
    margin-top: -2px !important;
    background-color: $white-five;
    border-top: solid 1px $light-periwinkle;
    border-bottom: solid 1px $light-periwinkle;
    display: flex;
    justify-content: center;
    align-items: center;
}
.categories-title {
    font-family: 'Raleway';
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    margin-left: 225px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $greyish-brown;
    margin-bottom: 22px;
    align-self: flex-start;
}
.category-card {
    width: 118px;
    height: 158px;
    border-radius: 12px;
    box-shadow: 0 5px 16px 0 $very-light-pink-three;
    border: solid 1px $pale-lilac;
    background-color: $white-three;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-right: 7px;
    cursor: pointer;
    position: relative;
    margin-bottom: 36px;
}
.category-card:hover {
    width: 118px;
    height: 158px;
    border-radius: 12px;
    box-shadow: 0 5px 16px 0 $very-light-pink-three;
    border: solid 1px $pale-lilac;
    background-color: $white-three;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-right: 7px;
    cursor: pointer;
    position: relative;
    margin-bottom: 30px;
}
.category-card-has-subscription {
    width: 156px;
    height: 128px;
    border-radius: 12px;
    box-shadow: 0 5px 16px 0 $very-light-pink-three;
    border: solid 1px $pale-lilac;
    background-color: $white-three;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-right: 14px;
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;
    text-decoration: none;
}
.category-card-has-subscription:hover {
    width: 156px;
    height: 128px;
    border-radius: 12px;
    box-shadow: 0 5px 16px 0 $very-light-pink-three;
    border: solid 1px $pale-lilac;
    background-color: $white-three;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-right: 14px;
    cursor: pointer;
    position: relative;
    text-decoration: none;
}
.category-card--faded {
    opacity: 0.63;
}
.specialties-img {
    content: url('../../images/home/speciality.svg');
}
.diagnapp-img {
    content: url('../../images/home/diagnapp.svg');
}
.smart-leaflets-img {
    content: url('../../images/home/medicine-leaflet.svg');
}
.score-img {
    content: url('../../images/home/score.svg');
}
.criteria-img {
    content: url('../../images/home/criteria.svg');
}
.laboratory-img {
    content: url('../../images/home/laboratory.svg');
}
.calculator-img {
    content: url('../../images/home/calculate.svg');
}
.semiology-img {
    content: url('../../images/home/semiology.svg');
}
.toxicology-img {
    content: url('../../images/home/toxicology.svg');
}
.notification-img {
    content: url('../../images/home/notification.png');
}
.medicine-interactions-img {
    content: url('../../images/home/interaction.svg');
}
.cid-code-img {
    content: url('../../images/home/cid.svg');
}
.pediatric-dose-img {
    content: url('../../images/home/pediatric-dose.svg');
}
.sus-procedure-img {
    content: url('../../images/home/sus.svg');
}
.divider-on-duty-img {
    content: url('../../images/home/timer.svg');
}
.flashcard-img {
    content: url('../../images/home/flashcard.svg');
}
.vaccination-img {
    content: url('../../images/home/vaccination.svg');
}
.protocols-img {
    content: url('../../images/home/protocols.svg');
}
.procedure-layout-img {
    content: url('../../images/home/ilustrated-procedures.svg');
}

.category-img {
    width: 64px;
    height: 64px;
    // object-fit: contain;
}

.category-label {
    font-family: 'Raleway';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.38px;
    text-align: center;
    color: $greyish-brown;
    margin-top: 17px;
    padding-right: 15px;
    padding-left: 15px;
    white-space: pre-line;
}
.free-flag-img {
    width: 46px;
    height: 42px;
    object-fit: contain;
    content: url('../../images/free-flag.svg');
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -2px;
    margin-right: -2px;
    z-index: 1;
}
.line-separator {
    height: 1px;
    width: 100%;
    background-color: $light-periwinkle;
}

.align-items-center {
    align-items: center;
}

.lists-container {
    border: solid 1px $light-periwinkle;
    background-color: $white-three;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    margin-top: 36px !important;
}

.vertical-line {
    height: auto;
    width: 2px;
    background-color: $light-periwinkle;
}

.home-list {
    ul {
        list-style-type: none;
        padding: 0px;
    }
    .container {
        width: '100%';
        flex-direction: column;
        max-height: 459px;
        padding-right: 10px;
        padding-left: 27px;
    }
    .title {
        font-family: 'Raleway';
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.8;
        letter-spacing: 0.5px;
        color: $greyish-brown;
    }

    .icon {
        width: 17px;
        height: 16px;
        margin-right: 8px;
    }

    .title-line {
        height: 1px;
        width: '100%';
        margin-top: 10px;
    }
    .title-line--gray {
        background-color: $light-periwinkle;
    }
    .title-line--orange {
        background-color: $pumpkin;
    }
    .section-list {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 340px;
        max-height: 340px;
        margin-bottom: 35px;
        .section {
            margin-top: 21px;
        }
    }
    .section-title {
        font-family: 'AccordAlt';
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $greyish-brown;
    }
    .bacteria-img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        content: url('../../images/bacterium-cinza.svg');
        filter: brightness(0) saturate(100%) invert(61%) sepia(66%) saturate(3781%) hue-rotate(3deg) brightness(95%) contrast(104%);
        margin-right: 8px;
        margin-bottom: 2px;
    }
    .leaflet-img {
        width: 25px;
        height: 29px;
        object-fit: fill;
        content: url('../../images/favorite/favorite-leaflet.svg');
        margin-right: 8px;
        margin-bottom: 2px;
    }
    .cid-img {
        width: 22px;
        height: 26px;
        object-fit: none;
        content: url('../../images/favorite/favorite-cid.svg');
        margin-right: 8px;
        margin-bottom: 2px;
    }
    .sus-img {
        width: 40px;
        height: 33px;
        object-fit: contain;
        content: url('../../images/favorite/favorite-sus.svg');
        margin-right: 4px;
        margin-left: -4px;
    }
    .dose-img {
        width: 31px;
        height: 31px;
        object-fit: contain;
        content: url('../../images/favorite/favorite-dose.svg');
        margin-right: 4px;
        margin-left: -4px;
    }
    .calculate-img {
        width: 31px;
        height: 31px;
        object-fit: contain;
        content: url('../../images/favorite/favorite-calculate.svg');
        margin-right: 4px;
        margin-left: -4px;
    }
    .score-icon {
        width: 31px;
        height: 31px;
        object-fit: contain;
        content: url('../../images/favorite/favorite-score.svg');
        margin-right: 4px;
        margin-left: -4px;
    }
    .criteria-icon {
        width: 31px;
        height: 31px;
        object-fit: contain;
        content: url('../../images/favorite/favorite-criteria.svg');
        margin-right: 4px;
        margin-left: -4px;
    }
    .laboratory-img {
        width: 32px;
        height: 26px;
        object-fit: contain;
        content: url('../../images/favorite/favorite-laboratory.svg');
        margin-right: 4px;
        margin-left: -4px;
    }
    .semiology-img {
        width: 34px;
        height: 31px;
        object-fit: contain;
        content: url('../../images/favorite/favorite-semiology.svg');
        margin-right: 4px;
        margin-left: -4px;
    }
    .procedure-img {
        width: 31px;
        height: 31px;
        object-fit: contain;
        content: url('../../images/favorite/favorite-procedure.svg');
        margin-right: 4px;
        margin-left: -4px;
    }
    .item-list {
        //
    }
    .item-text {
        font-family: 'Raleway';
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        color: $greyish-brown;
    }
    .item-subtitle {
        font-family: 'Raleway';
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        color: $brownish-grey;
    }
    .item-line {
        height: 1px;
        // width: '100%';
        background-color: $very-light-pink-three;
        margin-top: 8px;
    }
    .item {
        margin-top: 11px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
    }

    .empty-list {
        display: flex;
        flex-direction: column;
        height: 374px;
        justify-content: center;
        align-items: center;
        margin: 0px;
    }
    .empty-list__text {
        font-family: 'AccordAlt';
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: 0.21px;
        text-align: center;
        color: $very-light-pink;
    }
    .empty-list__text--highlighted {
        color: $pumpkin;
        cursor: pointer;
    }
    .empty-list__lock-img {
        width: 16px;
        height: 16px;
        object-fit: contain;
        content: url('../../images/bloqueado.svg');
        margin-bottom: 17px;
    }

    .filters {
        display: flex;
        flex-direction: row;
        align-items: center;
        .filters__disease-img {
            width: 24px;
            height: 24px;
            object-fit: contain;
            content: url('../../images/bacterium-cinza.svg');
            margin-right: 18px;
            cursor: pointer;
        }
        .filters__disease-img:hover {
            filter: brightness(0) saturate(100%) invert(50%) sepia(18%) saturate(19%) hue-rotate(315deg) brightness(92%) contrast(90%);
        }
        .filters__disease-img--orange {
            filter: brightness(0) saturate(100%) invert(61%) sepia(66%) saturate(3781%) hue-rotate(3deg) brightness(95%) contrast(104%);
        }
        .filters__leaflet-img {
            width: 18px;
            height: 19px;
            object-fit: contain;
            content: url('../../images/bula.svg');
            margin-right: 18px;
            cursor: pointer;
        }
        .filters__leaflet-img:hover {
            filter: brightness(0) saturate(100%) invert(50%) sepia(18%) saturate(19%) hue-rotate(315deg) brightness(92%) contrast(90%);
        }
        .filters__leaflet-img--orange {
            filter: brightness(0) saturate(100%) invert(61%) sepia(66%) saturate(3781%) hue-rotate(3deg) brightness(95%) contrast(104%);
        }
        .filters__cid-img {
            width: 18px;
            height: 18px;
            object-fit: contain;
            content: url('../../images/cid.svg');
            margin-right: 14px;
            cursor: pointer;
        }
        .filters__cid-img:hover {
            filter: brightness(0) saturate(100%) invert(50%) sepia(18%) saturate(19%) hue-rotate(315deg) brightness(92%) contrast(90%);
        }
        .filters__cid-img--orange {
            filter: brightness(0) saturate(100%) invert(61%) sepia(66%) saturate(3781%) hue-rotate(3deg) brightness(95%) contrast(104%);
        }
        .filters__sus-img {
            width: 31px;
            height: 31px;
            object-fit: contain;
            content: url('../../images/procedimento-sus-cinza.svg');
            margin-right: 18px;
            cursor: pointer;
        }
        .filters__sus-img:hover {
            content: url('../../images/procedimento-sus-cinza-escuro.svg');
        }
        .filters__sus-img--orange {
            content: url('../../images/procedimento-sus-laranja.svg');
        }
    }
}
