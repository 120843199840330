@import '../../App.scss';

.footer {
    width: 100%;
    height: 50px;
    background-color: $white-three;
    // position: absolute;
    // bottom: 0;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-top: solid 1px $light-periwinkle;
}
.footer__text {
    font-family: 'Raleway';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9b9b9b;
}