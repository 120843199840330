@import '../../../App.scss';

.component {
    // height: 100%;
    width: 600px;
    display: flex;
    padding-top: 40px;
    flex-direction: column;
    // overflow-y: auto;
    // overflow-x: hidden;
    padding-right: 20px;
}

.component__title {
    font-family: 'AccordAlt';
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: 0.33px;
    color: $greyish-brown;
    margin-bottom: 20px;
}

.component__content {
    display: flex;
    flex-direction: column;
    padding-left: 3px;
}
.component__card-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin-bottom: 6px;
}
.component__card-item__content {
    border-radius: 4px;
    box-shadow: 0 0 4px 0 $beige;
    background-color: $white-three;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    z-index: 1;
    height: 46px;
}
.component__card-item__content__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 21px;
    padding-right: 17px;
}
.component__card-item__label {
    font-family: 'Raleway';
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.65px;
    color: $charcoal-grey;
}
.component__card-item__extension {
    height: 41px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -2px;
    background-color: $pumpkin;
    padding-left: 3px;
    padding-right: 3px;
}
.arrow-right-img {
    width: 11px;
    height: 11px;
    object-fit: contain;
    content: url('../../../images/avancar-laranja.svg');
}
.profile-title-row {
    display: flex;
    flex-direction: row;
    position: relative;
}
.profile-content {
    display: flex;
    flex-direction: column;
}
.profile__button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .edit-button {
        width: 191px;
        height: 51px;
        border-radius: 32px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
        border: solid 1px $very-light-pink-four;
        background-color: $white-three;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 36px;
        cursor: pointer;
        .edit-button__label {
            font-family: 'Raleway';
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $pumpkin;
        }
    }
}
.profile-success-popup {
    position: absolute;
    top: -20px;
    right: 0px;
    width: 277px;
    height: 60px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px $light-periwinkle;
    background-color: $white-three;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 25px;
    z-index: 2;
    .profile-success-popup__text {
        font-family: 'Raleway';
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.38px;
        color: $greyish-brown;
        width: 60%;
    }
    .profile-success-popup__close-icon {
        width: 18px;
        height: 18px;
        object-fit: contain;
        content: url('../../../images/fechar-2.svg');
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
}
.edit-password-content {
    display: flex;
    flex-direction: column;
}
.edit-password__button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.termsOfUse__text {
    font-family: 'Raleway';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.39px;
    color: $greyish-brown;
}

.subscription-plans {
    display: flex;
    flex-direction: row;
    .subscription-plans__card-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 42px;
        position: relative;
        .subscription-plans__active-header {
            width: 276px;
            height: 35px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 8px;
            left: 0px;
            .subscription-plans__active-header__text {
                font-family: 'Raleway';
                font-size: 14px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.43;
                letter-spacing: normal;
                text-align: center;
                color: $mango;
            }
            .subscription-plans__active-header__triangle {
                width: 0px;
                height: 0px;
                border-left: 9px solid transparent;
                border-right: 9px solid transparent;
                border-bottom: 9px solid $mango;
            }
        }
        .subscription-plans__card {
            width: 276px;
            min-height: 585px;
            border-radius: 12px;
            border: solid 2px #e7e8eb;
            background-color: $white-three;
            margin-right: 21px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 89px 16px 33px 16px;
            margin-top: 7px;
            position: relative;
            .subscription-plans__card__free-logo {
                width: 163px;
                height: 28px;
                object-fit: contain;
                content: url('../../../images/wemeds-gratis.svg');
                margin-bottom: 40px;
            }
            .subscription-plans__card__premium-logo {
                width: 210px;
                height: 30px;
                object-fit: contain;
                content: url('../../../images/wemeds-premium-2.svg');
                margin-bottom: 10px;
            }
            .subscription-plans__card__subtitle {
                margin-bottom: 5px;
                .subscription-plans__card__subtitle__text {
                    font-family: 'AccordAlt';
                    font-size: 16px;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: 0.29px;
                    text-align: center;
                    color: $greyish-brown;
                }
            }
            .subscription-plans__card__price {
                    display: flex;
                    flex-direction: row;
                    .subscription-plans__card__price__brl-sign {
                        font-family: 'AccordAlt';
                        font-size: 21px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        letter-spacing: 0.29px;
                        text-align: center;
                        color: $greyish-brown;
                        margin-top: -11px;
                        margin-right: 1px;
                    }
                    .subscription-plans__card__price__big {
                        font-family: 'AccordAlt';
                        font-size: 53px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 0.57;
                        letter-spacing: 0.74px;
                        text-align: center;
                        color: $greyish-brown;
                    }
                    .subscription-plans__card__price__small {
                        font-family: 'AccordAlt';
                        font-size: 25px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        letter-spacing: 0.35px;
                        text-align: center;
                        color: $greyish-brown;
                      
                    }
                    .subscription-plans__card__price__month {
                        font-family: 'AccordAlt';
                        font-size: 13px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        letter-spacing: 0.18px;
                        color: $brown-grey-two;
                        margin-top: -8px;
                        margin-left: 4px;
                    }
    
            }
            .subscription-plans__card__line {
                width: 100%;
                height: 1px;
                background-color: $light-periwinkle;
            }
            .subscription-plans__card__feature-list {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-top: 14px;
                .subscription-plans__card__feature-list__row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 2px;
                    .subscription-plans__card__feature-list__row__check-icon {
                        width: 14px;
                        height: 10px;
                        object-fit: contain;
                        content: url('../../../images/check.svg');
                        margin-right: 10px;
                    }
                    .subscription-plans__card__feature-list__row__x-icon {
                        width: 12px;
                        height: 12px;
                        object-fit: contain;
                        content: url('../../../images/fechar-5.svg');
                        margin-right: 10px;
                    }
                    .subscription-plans__card__feature-list__row__text {
                        font-family: 'AccordAlt';
                        font-size: 13px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 2.23;
                        letter-spacing: normal;
                        color: $greyish-brown;
                    }
                    .subscription-plans__card__feature-list__row__text--disable {
                        color: $very-light-pink;
                        
                    }
                }
            }
            .subscription-plans__card__price-subtitle {
                display: flex;
                flex-direction: row;
                align-items: center;
                .subscription-plans__card__price-subtitle__text {
                    font-family: 'AccordAlt';
                    font-size: 12px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.33;
                    letter-spacing: 0.23px;
                    color: $greyish-brown;
                }
                .subscription-plans__card__price-subtitle__text--disable {
                    color: $brown-grey-two;                  
                }
                .subscription-plans__card__price-subtitle__separator {
                    width: 1px;
                    height: 9px;
                    background-color: $very-light-pink;
                    margin: 0px 6px 0px 6px;
                }
            }
            .subscription-plans__card__subscribe-button-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                .subscription-plans__card__subscribe-button-container__subtitle {
                    font-family: 'AccordAlt';
                    font-size: 13px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: 0.8px;
                    text-align: center;
                    color: $greyish-brown;                  
                }
                .subscription-plans__card__subscribe-button-container__subtitle--bold {
                    font-weight: 500;
                }
            }
            .subscription-plans__card__cancel-button {
                width: 230px;
                height: 51px;
                border-radius: 32px;
                box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
                border: solid 1px $very-light-pink-four;
                background-color: $white-three;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-top: 27px;
                cursor: pointer;           
                .subscription-plans__card__cancel-button__label {
                    font-family: 'Raleway';
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: $pumpkin;                  
                }
            }
            .subscription-plans__card__subscribe-button {
                width: 232px;
                height: 50px;
                border-radius: 28.5px;
                background-image: linear-gradient(to right, #f75164 -4%, #f67d40 67%, #f9a042 106%);
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                margin-top: 26px;
                margin-bottom: 18px;
                .subscription-plans__card__subscribe-button__label {
                    font-family: 'Raleway';
                    font-size: 14px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.44px;
                    text-align: center;
                    color: $white-three;
                }
            }
            .subscription-plans__card__flag {
                width: 95px;
                height: 78px;
                position: absolute;
                top: 4px;
                right: 16px;
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 2;
                .subscription-plans__card__flag__background-img {
                    width: 95px;
                    height: 78px;
                    object-fit: contain;
                    content: url('../../../images/flag.svg');
                    position: absolute;
                    z-index: -1;
                    margin-top: -5px;
                }
                .subscription-plans__card__flag__text-1 {
                    font-family: 'AccordAlt';
                    font-size: 14px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.51;
                    letter-spacing: 0.1px;
                    text-align: center;
                    color: $white-three;
                }
                .subscription-plans__card__flag__text-2 {
                    font-family: 'AccordAlt';
                    font-size: 18px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: $white-three;                  
                }
                .subscription-plans__card__flag__text-3 {
                    font-family: 'AccordAlt';
                    font-size: 26px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: 0.19px;
                    color: $white-three;
                }
                .subscription-plans__card__flag__text-4 {
                    font-family: 'AccordAlt';
                    font-size: 12px;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: $white-three;                  
                }
            }
        }
        .subscription-plans__card--active {
            border-color: $mango;
        }
    }
}
