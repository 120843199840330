@import '../../App.scss';

.sus-screen-container {
    height: calc(100vh - 80px - 50px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 12px 0px 12px 0px;
}

.screen-title {
    font-family: 'AccordAlt';
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.53;
    letter-spacing: 0.5px;
    color: $greyish-brown;
    margin-bottom: 0px;
}


.sus-card-content {
    width: 100%;
    padding-left: 13px;
    padding-top: 11px;
    padding-bottom: 10px;
    padding-right: 10px;
}
.sus-card-content__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: center;
    justify-content: space-between;
}
.sus-card-content__row-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.sus-card-content__column-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.sus-card-content__title {
    font-family: 'Raleway';
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.65px;
    color: $charcoal-grey;
    margin-bottom: 0px;
    width: 133px;
    word-break: break-word;
}
.sus-card-content__title--light {
    font-weight: normal;
    color: $brownish-grey;
    margin-top: 8px;
    width: 206px;
}
.sus-card-content__code {
    font-family: 'Raleway';
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: center;
    margin-right: 10px;
}
.sus-card-content__code--golden {
    color: $golden;  
}
.sus-card-content__code--grey {
    color: $brown-grey-two;
}
.sus-card-content__code--green {
    color: $fern-green;
}
.arrow-right-img {
    width: 11px;
    height: 11px;
    object-fit: contain;
    content: url('../../images/avancar-laranja.svg');
    margin-left: 8px;
}
.sus-card-content__description {
    font-family: 'Raleway';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.65px;
    color: $brownish-grey;  
}

.last-column {
    width: 254px;
    min-width: 254px;
    height: inherit;
    border-radius: 4px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    padding-top: 15px;
    padding-bottom: 15px;
    overflow-y: auto;
}
.last-column__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding-bottom: 16px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);;
    padding-left: 20px;
    padding-right: 15px;
}
.last-column__content {
    padding-left: 20px;
    padding-right: 15px;
    padding-top: 30px;
    position: relative;
}

.last-column__font-img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    content: url('../../images/font-empty.svg');
    margin-right: 20px;
    cursor: pointer;
}
.last-column__font-img:hover {
    width: 24px;
    height: 24px;
    object-fit: contain;
    content: url('../../images/font-gray.svg');
    margin-right: 20px;
    cursor: pointer;
}
.last-column__font-img--orange {
    content: url('../../images/font-orange.svg');
}
.last-column__font-img--orange:hover {
    content: url('../../images/font-orange.svg');
}
.last-column__star-img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    content: url('../../images/empty-star.svg');
    cursor: pointer;
}
.last-column__star-img:hover {
    width: 24px;
    height: 24px;
    object-fit: contain;
    content: url('../../images/gray-star.svg');
    cursor: pointer;
}
.last-column__star-img--orange {
    content: url('../../images/orange-star.svg');
}
.last-column__star-img--orange:hover {
    content: url('../../images/orange-star.svg');
}
.last-column__code {
    font-family: 'Raleway';
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.88;
    letter-spacing: normal;
    color: $fern-green;
}
.last-column__description {
    margin-bottom: 30px;
    margin-top: 12px;
}
.last-column__description__text {
    font-family: 'Raleway';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: 0.74px;
    color: $greyish-brown;
    margin-bottom: 0px;
}
.last-column__slider-container {
    background-color: $white-three;
    width: 213px;
    height: 45px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    position: absolute;
    z-index: 1;  
    top: 0;
    right: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.last-column__slider {
    width: 133px!important;
    color: $very-light-pink-five!important;
}

.last-column__favorite-success-toast {
    background-color: $maize-two;
    width: 213px;
    height: 45px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.28);
    position: absolute;
    z-index: 1;  
    top: 0;
    right: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-right: 16px;
    padding-left: 16px;
}

.last-column__white-star-img {
    width: 19px;
    height: 18px;
    object-fit: contain;
    content: url('../../images/star-white.svg');
    margin-right: 8px;
}

.last-column__favorite-success-msg {
    font-family: 'Raleway';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $greyish-brown;  
}

.MuiSlider-markActive {
    background-color: #3eb5b1!important;
    opacity: 1!important;
}