@import '../../../App';

.white-binoculars-img {
    content: url('../../../images/flashcard/white-binoculars.svg');
}

.white-heart-img {
    content: url('../../../images/flashcard/white-heart.svg');
}

.orange-star-img {
    content: url('../../../images/flashcard/orange-star.svg');
}

.white-switch-button-img {
    content: url('../../../images/flashcard/white-switch-button.svg');
}

.white-gear-img {
    content: url('../../../images/flashcard/white-gear.svg');
}

.yellow-lightning-img {
    content: url('../../../images/flashcard/yellow-lightning.svg');
}

.orange-chart-line-up-img {
    content: url('../../../images/flashcard/orange-chart-line-up.svg');
}

.dark-grey-line-arrow-right-img {
    content: url('../../../images/flashcard/dark-grey-line-arrow-right.svg');
}

.see-content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 6px;
    position: relative;
    width: 146px;
    height: 36px;
    padding: 8px 12px 8px 12px;
    cursor: pointer;
    background: rgba(36, 114, 181, 0.15);
    border: none;
    z-index: 1;
    overflow: hidden;
    border-radius: 80px;
}

.theme-container {
    display: flex;
    align-self: flex-start;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 6px;
    position: relative;
    min-width: 186px;
    height: 36px;
    padding: 8px 12px 8px 12px;
    cursor: pointer;
    background: rgba(37, 114, 182, 0.10);
    border: none;
    z-index: 1;
    overflow: hidden;
    border-radius: 80px;
}
