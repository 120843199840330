@import '../../App.scss';

.protocols-card-content__icon {
    width: 50px;
    height: 50px;
    margin-right: 30px;
}

.protocols-card-content {
    width: 100%;
    padding-left: 13px;
    padding-top: 11px;
    padding-bottom: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}