@import '../../App.scss';

.Toaster__manager-bottom {
    bottom: 50px !important;
}
.custom-toast {
    width: auto;
    height: 43px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(209, 209, 209, 0.22);
    border: solid 1px $very-light-pink-eight;
    background-color: $white-three;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 17px 0px 14px;
    -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.5s; /* Firefox < 16 */
     -ms-animation: fadein 0.5s; /* Internet Explorer */
      -o-animation: fadein 0.5s; /* Opera < 12.1 */
         animation: fadein 0.5s;

    .custom-toast__warning-title {
        font-family: 'Frutiger-Roman';
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.08;
        letter-spacing: normal;
        text-align: center;
        color: $pale-red;
        margin: 0px;    
    }
    .custom-toast__title {
        font-family: 'Raleway';
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.08;
        letter-spacing: normal;
        color: $brownish-grey-two;
        margin: 0px 16px 0px 21px;
    }
    .custom-toast__button {
        width: 52px;
        height: 31px;
        border-radius: 15.5px;
        border: solid 1px $brown-grey-two;
        display: flex;
        align-self: center;
        justify-content: center; 
        cursor: pointer;
        .custom-toast__button__label {
            font-family: 'Raleway';
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
            text-align: center;
            color: $brown-grey-four;          
        }
    }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
