@import '../../App.scss';

.card-item_content {
    border-radius: 0px !important; 
    box-shadow: 0 0 0px 0 transparent !important;
    background-color: transparent !important;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.fast-reference {
    width: 104px;
    cursor: pointer;
}

.laboratory-card-content {
    width: 100%;
    padding-left: 13px;
    padding-top: 11px;
    padding-bottom: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.laboratory-card-content__left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.laboratory-card-content__title {
    font-family: 'Raleway';
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.55px;
    color: $charcoal-grey;
}
.laboratory-card-content__icon {
    width: 28px;
    height: 28px;
    margin-left: -7px;
    margin-right: 8px;
}