@import '../../App.scss';

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

  
.annotations {
    width: 316px;
    height: 537px;
    border-radius: 4px;
    border: solid 1px $light-periwinkle;
    background-color: $white-three;
    margin: 35px 25px 0px 25px;
    padding: 60px 25px 0px 25px;
    .annotations__menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        position: relative;
        .annotations__undo {
            display: flex;
            flex-direction: row;
            align-self: flex-start;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 0px;
            top: -35px;
            cursor: pointer;
            .annotations__undo__icon {
                width: 15px;
                height: 18px;
                object-fit: contain;
                content: url('../../images/undo.svg');
                margin-right: 9px;
            }
            .annotations__undo__text {
                font-family: 'AccordAlt';
                font-size: 15.2px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: 0.23px;
                color: $brown-grey-two;
            }
        }
        .annotations__saving {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            align-self: flex-end;
            position: absolute;
            right: 0px;
            top: -35px;
            .annotations__saving__icon {
                width: 15px;
                height: 15px;
                object-fit: contain;
                content: url('../../images/loading-orange.png');
                margin-right: 9px;
                animation: spin 1s linear infinite;
            }
            .annotations__saving__text {
                font-family: 'AccordAlt';
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: 0.21px;
                color: $very-light-pink;
            }
            
        }
    }
    .annotations__text:focus {
        outline: none;
    }
    .annotations__text::placeholder {
        font-family: 'AccordAlt';
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.88;
        letter-spacing: 0.24px;
        color: $very-light-pink;    
        text-align: left; 
    }    
    .annotations__text {
        border: none; 
        width: 263px;
        height: 450px;
        font-family: 'Raleway';
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 0.21px;
        color: $greyish-brown;
        text-align: justify;
        resize: none;
    }
}