@import '../../App.scss';

.card-item_content {
    border-radius: 0px !important; 
    box-shadow: 0 0 0px 0 transparent !important;
    background-color: transparent !important;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.on-duty-card-icon-left {
    width: 32px;
    height: 32px;
    object-fit: contain;
    content: url('../../images/arrow-left.svg');
    &--disabled {
        @extend .on-duty-card-icon-left;
        content: url('../../images/arrow-left-disabled.svg');
    }
}

.on-duty-card-icon-right {
    width: 32px;
    height: 32px;
    object-fit: contain;
    content: url('../../images/arrow-right.svg');
}

.on-duty-card-download-right {
    width: 32px;
    height: 32px;
    object-fit: contain;
    content: url('../../images/download.svg');
}

.on-duty-dots {
    width: 24px;
    height: 24px;
    margin-left: 7px;
    object-fit: contain;
    content: url('../../images/dots.svg');
}

.on-duty-circle-cross {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    content: url('../../images/cross-circle.svg');
}

.on-duty-circle-plus {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    content: url('../../images/plus-circle.svg');
}

.on-duty-start-clock {
    width: 24px;
    height: 24px;
    margin-left: 18px;
    margin-right: 19px;
    content: url('../../images/icons/start-clock.svg');
}

.on-duty-end-clock {
    width: 24px;
    height: 24px;
    margin-left: 18px;
    margin-right: 19px;
    content: url('../../images/icons/end-clock.svg');
}

.on-duty-pencil {
    width: 20px;
    height: 20px;
    content: url('../../images/icons/pencil.svg');
}

.on-duty-info {
    width: 20px;
    height: 20px;
    content: url('../../images/icons/info.svg');
}

.on-duty-logo {
    width: 64px;
    height: 64px;
    content: url('../../images/icons/logo.svg');
}

.on-duty-qr-code {
    width: 72px;
    height: 72px;
}