@import '.././../App.scss';

/**************************************/
/* INPUTS */
/**************************************/

.text__field {
    font-family: Raleway;

    .check-icn {
        width: 20px;
        height: 20px;
        content: url('../../images/confirm-icn.png');
    }

    .MuiInputBase-input {
        padding: 27px 10px 7px;
    }

    // .MuiInputLabel-filled {
    //     transform: translate(11px, 16px) scale(1);
    // }

    .helpText {
        margin-top: -5px;
        width: 100%;
        padding: 10px;
        position: relative;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border: solid 1px #b9b9b9;
        background-color: #ffffff;
        text-align: center;
        align-content: center;
        align-items: center;
        font-size: 0.8125rem;
        letter-spacing: 0.54px;
        color: #4a4a4a;
    }

    .MuiFilledInput-root {
        // border: solid 1px #e8ebec;
        border-radius: 6px;
        background: #f6f6f6;
        color: #2d3e46;
        font-family: Raleway;
        font-size: 1rem;
    }

    &.text__has-value .MuiFilledInput-root,
    .MuiFilledInput-root:hover {
        background: #f6f6f6;
        color: black;
    }

    &.text__has-value .MuiFilledInput-root,
    .MuiFilledInput-root.Mui-focused {
        background: white;
        // border: solid 1px #b9b9b9;
        overflow: hidden;
    }

    .MuiFilledInput-underline:after {
        border: none;
    }

    .MuiFilledInput-underline:before {
        display: none;
    }

    .MuiFormLabel-root,
    .MuiFormLabel-root.Mui-focused {
        color: #9199a1;
        font-family: Raleway;
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0.67px;
        @include responsive(s) {
            // max-width: 260px;
        }
    }

    .MuiSvgIcon-root {
        color: green;
    }

    .MuiFormHelperText-root.Mui-error {
        font-size: 0.813rem;
        font-family: Raleway;
    }

    .error {
        background: none;
        font-family: Raleway;
        .MuiFilledInput-root {
            background-color: #ffffff;
            top: 0;
            border-radius: 6px;
            border-top: 2px solid #f44336;
        }
        .MuiFormLabel-root.Mui-error {
            color: #9199a1;
        }
        .MuiFilledInput-underline::after {
            top: 0;
            border: none;
        }
        .MuiInputBase-input:focus {
            background-color: #ffffff;
            border: none;
        }
        .MuiInputBase-input.MuiFilledInput-input {
            border: none;
        }
    }

    .validate {
        .MuiFilledInput-underline:after {
            border-bottom: none;
        }
        .MuiFormLabel-root.Mui-error {
            color: #9199a1;
        }
        .MuiFilledInput-root {
            background-color: #ffffff;
            top: 0;
            border-radius: 6px;
            border-top: 2px solid #5ab0ae;
        }
        .MuiInputBase-input:focus {
            background-color: #ffffff;
            border: none;
        }

        .MuiInputBase-input.MuiFilledInput-input {
            border: none;
        }
    }
}
