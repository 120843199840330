@import '../../App.scss';

.dose-main-container {
    height: calc(100vh - 80px - 50px);
    display: flex;
    padding-top: 55px;
    justify-content: center;
}

.screen-dose-search-container {
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 12px;
}

.screen-dose-content-container {
    height: 100%;
    width: 40vw;
    padding-top: 12px;
    margin-left: 60px;
}

.search-title {
    font-family: 'AccordAlt';
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: $greyish-brown;
    margin-bottom: 30px;
    margin-top: 15px;
}

.dose-search-container {
    display: flex;
    width: 350px;
    height: 90%;
    border-radius: 8px;
    background: #FFFFFF;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #E1E2E6;
}

.dose-content-container {
    overflow-y: scroll;
    height: 70vh;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
}

.calculator-container {
    margin-top: 11px;
    margin-bottom: 13px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.dose-section-container {
    margin-top: 15px;
}

.dose-section-name {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #ABB0B2;
}

.dose-name {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    color: #1A3C47;
    font-size: 13px;
    line-height: 20px;
}

.pediatrc-dose-container {
    margin-left: 0px;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
}

.dose-header-container {
    height: 46px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 14px;
}

.dose-icons-container {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.score-header-name {    
    font-family: 'AccordAlt';
    font-style: normal;
    font-weight: 700;
    width: 90%;
    font-size: 20px;
    line-height: 16px;
    color: #4A4A4A;
}

.dose-icon-favorite {
    content: url('../../images/icons/star-off.svg');
    height: 22px;
    width: 22px;
    margin-left: 20px;
    cursor: pointer;
    &--on {
        @extend .dose-icon-favorite;
        content: url('../../images/icons/star-on.svg');
    }
}

.dose-icon-pencil {
    content: url('../../images/icons/pencil-off.svg');
    height: 22px;
    width: 22px;
    cursor: pointer;
    &--on {
        @extend .dose-icon-pencil;
        content: url('../../images/icons/pencil-on.svg');
    }
}

.dose-icon-font-resizer {
    content: url('../../images/icons/font-resizer-off.svg');
    height: 34px;
    width: 34px;
    margin-left: 20px;
    cursor: pointer;
    &--on {
        @extend .dose-icon-font-resizer;
        content: url('../../images/icons/font-resizer-on.svg');
    }
}

.dose-header-medicine-button {    
    order: 0;
    flex: none;
    width: 98px;
    flex-grow: 0;
    height: 28px;
    display: flex;
    cursor: pointer;
    margin-right: 30px;
    border-radius: 80px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 1px solid #EF7D00;
}

.dose-header-medicine-button-text {    
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #EF7D00;
}

.dose-markdown {
    margin-left: 0px;
    width: auto;
    padding-top: 31px;
    hr {
        margin-top: 0px;
    }
    ul li {
        margin-bottom: 12px;
    }
    .dose-markdown__paragraph {
        font-family: 'Raleway';
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: 0.5px;
        color: $greyish-brown;
        margin-bottom: 12px;
        ul li {
            margin-bottom: 0px;
        }
    }
    .dose-markdown__strong {
        font-weight: bold;
    }
    .dose-markdown__highlighted {
        background-color: yellow;
    }

    .dose-markdown__heading {
        font-family: 'Raleway';
        font-size: 18px !important;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.11;
        letter-spacing: 0.5px;
        color: $dark-blue-grey;
    }
    .dose-markdown__heading span {
        font-weight: 600;
    }
    .dose-markdown__emphasis {
        font-family: 'Raleway';
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.57;
        letter-spacing: 0.5px;
        color: $greyish-brown;
        margin-bottom: 0px;
    }
    .dose-markdown__link {
        font-family: 'Raleway';
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 1.57;
        letter-spacing: 0.5px;
        color: $dusty-orange;
        margin-bottom: 0px;
    }
  
    .dose-markdown__list-item {
        font-family: 'Raleway';
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: 0.5px;
        color: $greyish-brown;
    }

    .dose-observation-markdown__list-item {
        font-family: 'Raleway';
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: 0.5px;
        color: $greyish-brown;
        background: url('../../images/icons/warning-white.svg') no-repeat left top; /* <-- change `left` & `top` too for extra control */
        padding: 0px 0px 1px 23px;
        &::marker {
            content: '';
        }
    }

    .dose-markdown__code {
        font-family: 'Raleway';
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: 0.5px;
        color: $greyish-brown;
        padding: 8px;
    }
    .dose-markdown__blockquote {
        display: flex;
        justify-content: center;
        width: 100%;
        .dose-markdown__blockquote__content {
            width: 40%;
        }
    }
    .dose-markdown__blockquote div {
        font-family: 'Raleway';
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.36px;
        color: $brown-grey-three;
    }
    .dose-markdown__blockquote div a {
        color: $tangerine-two;
    }
    
}

.dose-markdowns-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 79%;
}

.dose-content {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.dose-content__markdown {
    height: calc(100vh - 80px - 50px - 60px);
    width: auto;
}

.dose-observations {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #E04444;
}

.dose-markdown__formula-container-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #2D2D2D;
    height: 24px;
    border: 1px #FECF44;
    border-radius: 3px;
    background: #FECF44;
    padding-top: 3px;
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 2px;
    &--inError {
        @extend .dose-markdown__formula-container-text;
        background: #FFDBDB;
    }
}

.lock-img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    content: url('../../images/bloqueado.svg');
    margin-left: 8px;
}

.annotations {
    width: 273px;
    height: 537px;
    border-radius: 4px;
    border: solid 1px $light-periwinkle;
    background-color: $white-three;
    margin: 35px 25px 0px 25px;
    padding: 60px 25px 0px 25px;
    .annotations__menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        position: relative;
        .annotations__undo {
            display: flex;
            flex-direction: row;
            align-self: flex-start;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 0px;
            top: -35px;
            cursor: pointer;
            .annotations__undo__icon {
                width: 15px;
                height: 18px;
                object-fit: contain;
                content: url('../../images/undo.svg');
                margin-right: 9px;
            }
            .annotations__undo__text {
                font-family: 'AccordAlt';
                font-size: 15.2px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: 0.23px;
                color: $brown-grey-two;
            }
        }
        .annotations__saving {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            align-self: flex-end;
            position: absolute;
            right: 0px;
            top: -35px;
            .annotations__saving__icon {
                width: 15px;
                height: 15px;
                object-fit: contain;
                content: url('../../images/loading-orange.png');
                margin-right: 9px;
                animation: spin 1s linear infinite;
            }
            .annotations__saving__text {
                font-family: 'AccordAlt';
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: 0.21px;
                color: $very-light-pink;
            }
            
        }
    }
    .annotations__text:focus {
        outline: none;
    }
    .annotations__text::placeholder {
        font-family: 'AccordAlt';
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.88;
        letter-spacing: 0.24px;
        color: $very-light-pink;    
        text-align: left; 
    }    
    .annotations__text {
        border: none; 
        width: 219px;
        height: 450px;
        font-family: 'Raleway';
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 0.21px;
        color: $greyish-brown;
        text-align: justify;
        resize: none;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}