@import '../../App.scss';

.specialty-screen-container {
    height: calc(100vh - 80px - 50px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 12px 0px 12px 0px;
}

.screen-title {
    font-family: 'AccordAlt';
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.53;
    letter-spacing: 0.5px;
    color: $greyish-brown;
    margin-bottom: 0px;
}


.specialty-card-content {
    width: 100%;
    padding-left: 13px;
    padding-top: 11px;
    padding-bottom: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.specialty-card-content__left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.specialty-card-content__title {
    font-family: 'Raleway';
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.55px;
    color: $charcoal-grey;
}
.specialty-card-content__icon {
    width: 28px;
    height: 28px;
    margin-left: -7px;
    margin-right: 8px;
    filter: brightness(0) saturate(100%) invert(74%) sepia(52%) saturate(7220%) hue-rotate(6deg) brightness(98%) contrast(102%);
}

.lock-img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    content: url('../../images/bloqueado.svg');
    margin-left: 8px;
}
.arrow-right-img {
    width: 11px;
    height: 11px;
    object-fit: contain;
    content: url('../../images/avancar-laranja.svg');
    margin-left: 8px;
}

.loading-overlay {
    position: absolute;
    background-color: white;
    opacity: 0.6;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 00px;
    width: 100%;
    height: calc(100vh - 80px - 50px);
    z-index: 2;
}
.loading-img {
    width: 50px;
    height: 50px;
    content: url('../../images/wemeds-loading.gif');
}