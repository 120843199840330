@import '../../App.scss';

/**************************************/
/* INPUTS */
/**************************************/
.check-icn {
    width: 20px;
    height: 20px;
    content: url('../../images/confirm-icn.png');
}

.radio-click-area {
    width: 50%
}

.check-radio-blank {
    border-radius: 50%;
    width: 27px;
    height: 27px;
    border: solid 1px #b9b9b9;
    background-color: #ffffff;
}

.check-radio {
    width: 27px;
    height: 27px;
    content: url('../../images/radio-checked.svg');
}
