@import '../../../App.scss';

.dose-input-main-container {
    height: 64px;
    width: 100%;
    display: flex;
    border-radius: 6px;
    background: #FAFAFA;
}

.dose-input-left-container {
    width: 100%;
    height: 100%;
    display: flex;
    padding-right: 6px;
    align-items: center;
    justify-content: space-between;
}

.dose-input-right-container {
    width: 100%;
    height: 100%;
    display: flex;
    padding-left: 6px;
    align-items: center;
    justify-content: flex-start;
}

.dose-input-icon-warning {
    content: url('../../../images/icons/warning.svg');
    height: 32px;
    width: 32px;
    margin-left: 12.5px;
}

.dose-input-input {
    width: 50px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 6px;
    caret-color: #EF7D00;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #1A3C47;
    text-align: center;

    &--inError {
        @extend .dose-input-input;
        border: 1px solid #E04444;
        border-radius: 6px;
    }
}

.dose-input-type-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #1A3C47;
    margin-right: 12px;
}