@import '../../App.scss';

.radio-click-area {
    margin-top: 25px;
    display: flex;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 40px;
    &--on {
        @extend .radio-click-area;
        background: #F4F5F5;
        border-radius: 28px;
    }
    &--off {
        @extend .radio-click-area;
        background: transparent;
    }
}

.radio-item-container {
    display: flex;
    align-items: center;
}

.check-radio {
    width: 16px;
    height: 16px;
    &--on {
        @extend .check-radio;
        content: url('../../images/icons/radio-on.svg');
    }
    &--off {
        @extend .check-radio;
        content: url('../../images/icons/radio-off.svg');
    }
}

.radio-placeholder {
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #414E52;
}
