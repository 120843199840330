@font-face {
    font-family: 'Raleway';
    src: url(./fonts/Raleway-Black.ttf);
    font-weight: 700;
}

@font-face {
    font-family: 'Raleway';
    src: url(./fonts/Raleway-BlackItalic.ttf);
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Raleway';
    src: url(./fonts/Raleway-Bold.ttf);
    font-weight: 600;
}

@font-face {
    font-family: 'Raleway';
    src: url(./fonts/Raleway-BoldItalic.ttf);
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Raleway';
    src: url(./fonts/Raleway-Medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: 'Raleway';
    src: url(./fonts/Raleway-MediumItalic.ttf);
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Raleway';
    src: url(./fonts/Raleway-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: 'Raleway';
    src: url(./fonts/Raleway-Italic.ttf);
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Raleway';
    src: url(./fonts/Raleway-Light.ttf);
    font-weight: 300;
}

@font-face {
    font-family: 'Raleway';
    src: url(./fonts/Raleway-LightItalic.ttf);
    font-weight: 300;
    font-style: italic;
}


@font-face {
    font-family: 'AccordAlt';
    src: url(./fonts/AccordAltExtraBold.otf);
    font-weight: 700;
}
@font-face {
    font-family: 'AccordAlt';
    src: url(./fonts/AccordAltBold.otf);
    font-weight: 600;
}
@font-face {
    font-family: 'AccordAlt';
    src: url(./fonts/AccordAltMedium.otf);
    font-weight: 500;
}
@font-face {
    font-family: 'AccordAlt';
    src: url(./fonts/AccordAltRegular.otf);
    font-weight: 400;
}
@font-face {
    font-family: 'AccordAlt';
    src: url(./fonts/AccordAltLight.otf);
    font-weight: 300;
}
@font-face {
    font-family: 'AccordAlt';
    src: url(./fonts/AccordAltExtraLight.otf);
    font-weight: 200;
}
@font-face {
    font-family: 'AccordAlt';
    src: url(./fonts/AccordAltThin.otf);
    font-weight: 100;
}
@font-face {
    font-family: 'Frutiger-Roman';
    src: url(./fonts/Frutiger-Roman.ttf);
    font-weight: normal;
}